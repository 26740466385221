var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { throttle } from 'lodash';
import { DATE_FORMATS } from '../../helpers/constants';
// initial state
const state = {
    upSellDuringAdditionalPayment: false,
    allowOrderItemEdit: false,
    taxRate: 0,
    bandName: '',
    statementDescriptor: '',
    userAgreement: '',
    textSigninPrompt: '',
    textProfilePhotoPrompt: '',
    bannerMessage: '',
    showBannerMessage: false,
    distributionUrl: '',
    distributionEnabled: false,
    additionalPaymentsEnabled: false,
    showDistributionInfo: false,
    distributionInfo: '',
    contactInfo: '',
    enableAuthorizedCollectors: false,
    purchaseRequiresEntitlement: false,
    textAuthorizedPickupPrompt: '',
    convenienceFeePct: 0,
    convenienceFeeFlat: 0,
    enableResale: false,
    enableWatch: false,
    enableNewCart: false,
    liveBanner: '',
    askUsersForInstagramOnSignup: false,
    profileImageRequired: false,
    isPhoneNumberRequired: false,
    userPhoneShown: false,
    profileImageShown: false,
    userAddressShown: false,
    userAddressRequired: false,
    userInstagramRequired: false,
    userGenderShown: false,
    userGenderRequired: false,
    userDOBShown: false,
    userDOBRequired: false,
    userCountryShown: false,
    userCountryRequired: false,
    newOrdersActive: false,
    enableAdditionalCommentsOnOrderItem: false,
    facebookPixelId: '',
    cartMaxItems: 0,
    useEventCatalog: false,
    enablePackageOwners: false,
    allowSectionLeaderConnectAccount: false,
    paymentProcessor: '',
    cartExpiryTime: 0,
    dateOfBirthFormat: DATE_FORMATS['MM-DD-YYYY'],
    minCustomerAge: 18,
};
// getters
const getters = {
    upSellDuringAdditionalPayment: (_state) => _state.upSellDuringAdditionalPayment,
    allowOrderItemEdit: (_state) => _state.allowOrderItemEdit,
    taxRate: (_state) => _state.taxRate,
    bandName: (_state) => _state.bandName,
    statementDescriptor: (_state) => _state.statementDescriptor,
    userAgreement: (_state) => _state.userAgreement,
    convenienceFeePct: (_state) => _state.convenienceFeePct,
    convenienceFeeFlat: (_state) => _state.convenienceFeeFlat,
    marketplaceActive: () => Parse.Config.current().get("marketplaceActive"),
    textSigninPrompt: (_state) => _state.textSigninPrompt,
    textProfilePhotoPrompt: (_state) => _state.textProfilePhotoPrompt,
    showBannerMessage: (_state) => _state.showBannerMessage,
    bannerMessage: (_state) => _state.bannerMessage,
    distributionUrl: (_state) => _state.distributionUrl,
    distributionEnabled: (_state) => _state.distributionEnabled,
    currency: () => paidutils.getCurrencyCode(),
    additionalPaymentsEnabled: (_state) => _state.additionalPaymentsEnabled,
    showDistributionInfo: (_state) => _state.showDistributionInfo,
    distributionInfo: (_state) => _state.distributionInfo,
    contactInfo: (_state) => _state.contactInfo,
    enableAuthorizedCollectors: (_state) => _state.enableAuthorizedCollectors,
    purchaseRequiresEntitlement: (_state) => _state.purchaseRequiresEntitlement,
    textAuthorizedPickupPrompt: (_state) => _state.textAuthorizedPickupPrompt,
    webURL: () => Parse.Config.current().get('webURL'),
    enableResale: (_state) => _state.enableResale,
    enableWatch: (_state) => _state.enableWatch,
    enableNewCart: (_state) => _state.enableNewCart,
    liveBanner: (_state) => _state.liveBanner,
    askUsersForInstagramOnSignup: (_state) => _state.askUsersForInstagramOnSignup,
    profileImageRequired: (_state) => _state.profileImageRequired,
    isPhoneNumberRequired: (_state) => _state.isPhoneNumberRequired,
    userPhoneShown: (_state) => _state.userPhoneShown,
    profileImageShown: (_state) => _state.profileImageShown,
    userAddressShown: (_state) => _state.userAddressShown,
    userAddressRequired: (_state) => _state.userAddressRequired,
    userInstagramRequired: (_state) => _state.userInstagramRequired,
    userGenderShown: (_state) => _state.userGenderShown,
    userGenderRequired: (_state) => _state.userGenderRequired,
    userDOBShown: (_state) => _state.userDOBShown,
    userDOBRequired: (_state) => _state.userDOBRequired,
    userCountryShown: (_state) => _state.userCountryShown,
    userCountryRequired: (_state) => _state.userCountryRequired,
    newOrdersActive: (_state) => _state.newOrdersActive,
    enableAdditionalCommentsOnOrderItem: (_state) => _state.enableAdditionalCommentsOnOrderItem,
    facebookPixelId: (_state) => _state.facebookPixelId,
    cartMaxItems: (_state) => _state.cartMaxItems,
    useEventCatalog: (_state) => _state.useEventCatalog,
    enablePackageOwners: (_state) => _state.enablePackageOwners,
    allowSectionLeaderConnectAccount: (_state) => _state.allowSectionLeaderConnectAccount,
    paymentProcessor: (_state) => _state.paymentProcessor,
    cartExpiryTime: (_state) => _state.cartExpiryTime,
    isCartExpiryTimeEnabled: (_state) => {
        return _state.cartExpiryTime > 0;
    },
    dateOfBirthFormat: (_state) => _state.dateOfBirthFormat,
    minCustomerAge: (_state) => _state.minCustomerAge,
};
// actons
const actions = {
    getAppSettings: throttle(({ commit }) => __awaiter(void 0, void 0, void 0, function* () {
        const settings = yield paidutils.getAppSettings();
        const textSigninPrompt = paidutils.replaceMergeTags(settings.website_text_signin_prompt, settings);
        const textProfilePhotoPrompt = paidutils.replaceMergeTags(settings.website_text_profile_photo_prompt, settings);
        commit('upSellDuringAdditionalPayment', settings.upSellDuringAdditionalPayment);
        commit('allowOrderItemEdit', settings.allowOrderItemEdit);
        commit('taxRate', parseFloat(settings.salesTaxPct) || 0);
        commit('convenienceFeePct', parseFloat(settings.convenienceFeePct) || 0);
        commit('convenienceFeeFlat', parseFloat(settings.convenienceFeeFlat) || 0);
        commit('bandName', settings.band_name);
        commit('statementDescriptor', settings.statementDescriptor);
        commit('userAgreement', settings.userAgreement);
        commit('setTextSigninPrompt', textSigninPrompt);
        commit('setTextProfilePhotoPrompt', textProfilePhotoPrompt);
        commit('setBannerMessage', settings.bannerMessage);
        commit('setShowBannerMessage', settings.showBannerMessage);
        commit('setDistributionUrl', settings.distroClientUrl);
        commit('setDistributionEnabled', settings.distribution_enabled);
        commit('statementDescriptor', settings.statementDescriptor);
        commit('userAgreement', settings.userAgreement);
        commit('additionalPaymentsEnabled', settings.additionalPaymentsEnabled);
        commit('showDistributionInfo', settings.showDistributionInfo);
        commit('distributionInfo', settings.distributionInfo);
        commit('contactInfo', settings.contactInfo);
        commit('enableAuthorizedCollectors', settings.enableAuthorizedCollectors);
        commit('purchaseRequiresEntitlement', settings.purchaseRequiresEntitlement);
        commit('textAuthorizedPickupPrompt', paidutils.replaceMergeTags(settings.website_text_authorized_pickup_prompt, settings));
        commit('enableResale', settings.enableResale);
        commit('enableWatch', settings.enableWatch);
        commit('enableNewCart', settings.enableNewCart);
        commit('liveBanner', settings.liveBanner);
        commit('askUsersForInstagramOnSignup', settings.askUsersForInstagramOnSignup);
        commit('profileImageRequired', settings.profileImageRequired);
        commit('isPhoneNumberRequired', settings.isPhoneNumberRequired);
        commit('userPhoneShown', settings.userPhoneShown);
        commit('profileImageShown', settings.profileImageShown);
        commit('userAddressShown', settings.userAddressShown);
        commit('userAddressRequired', settings.userAddressRequired);
        commit('userInstagramRequired', settings.userInstagramRequired);
        commit('userGenderShown', settings.userGenderShown);
        commit('userGenderRequired', settings.userGenderRequired);
        commit('userDOBShown', settings.userDOBShown);
        commit('userDOBRequired', settings.userDOBRequired);
        commit('userCountryShown', settings.userCountryShown);
        commit('userCountryRequired', settings.userCountryRequired);
        commit('newOrdersActive', settings.newOrdersActive);
        commit('enableAdditionalCommentsOnOrderItem', settings.enableAdditionalCommentsOnOrderItem);
        commit('facebookPixelId', settings.facebookPixelId);
        commit('cartMaxItems', settings.cartMaxItems);
        commit('useEventCatalog', settings.useEventCatalog);
        commit('enablePackageOwners', settings.enablePackageOwners);
        commit('allowSectionLeaderConnectAccount', settings.allowSectionLeaderConnectAccount);
        commit('paymentProcessor', settings.paymentProcessor);
        commit('cartExpiryTime', settings.cartExpiryTime);
        commit('dateOfBirthFormat', settings.dateOfBirthFormat);
        commit('minCustomerAge', settings.minCustomerAge);
    }), 20000, { 'trailing': false })
};
// mutations
const mutations = {
    upSellDuringAdditionalPayment(state, upSellDuringAdditionalPayment) {
        state.upSellDuringAdditionalPayment = upSellDuringAdditionalPayment;
    },
    allowOrderItemEdit(state, allowOrderItemEdit) {
        state.allowOrderItemEdit = allowOrderItemEdit;
    },
    taxRate(state, taxRate) {
        state.taxRate = taxRate;
    },
    convenienceFeePct(state, convenienceFeePct) {
        state.convenienceFeePct = convenienceFeePct;
    },
    convenienceFeeFlat(state, convenienceFeeFlat) {
        state.convenienceFeeFlat = convenienceFeeFlat;
    },
    bandName(state, bandName) {
        state.bandName = bandName;
    },
    statementDescriptor(state, statementDescriptor) {
        state.statementDescriptor = statementDescriptor;
    },
    userAgreement(state, userAgreement) {
        state.userAgreement = userAgreement;
    },
    setTextSigninPrompt(state, textSigninPrompt) {
        state.textSigninPrompt = textSigninPrompt;
    },
    setTextProfilePhotoPrompt(state, textProfilePhotoPrompt) {
        state.textProfilePhotoPrompt = textProfilePhotoPrompt;
    },
    setBannerMessage(state, bannerMessage) {
        state.bannerMessage = bannerMessage;
    },
    setShowBannerMessage(state, showBannerMessage) {
        state.showBannerMessage = showBannerMessage;
    },
    setDistributionUrl(state, text) {
        state.distributionUrl = text;
    },
    setDistributionEnabled(state, isEnabled) {
        state.distributionEnabled = isEnabled;
    },
    additionalPaymentsEnabled(state, additionalPaymentsEnabled) {
        state.additionalPaymentsEnabled = additionalPaymentsEnabled;
    },
    showDistributionInfo(state, showDistributionInfo) {
        state.showDistributionInfo = showDistributionInfo;
    },
    distributionInfo(state, distributionInfo) {
        state.distributionInfo = distributionInfo;
    },
    contactInfo(state, contactInfo) {
        state.contactInfo = contactInfo;
    },
    enableAuthorizedCollectors(state, enableAuthorizedCollectors) {
        state.enableAuthorizedCollectors = enableAuthorizedCollectors;
    },
    purchaseRequiresEntitlement(state, purchaseRequiresEntitlement) {
        state.purchaseRequiresEntitlement = purchaseRequiresEntitlement;
    },
    textAuthorizedPickupPrompt(state, textAuthorizedPickupPrompt) {
        state.textAuthorizedPickupPrompt = textAuthorizedPickupPrompt;
    },
    enableResale(state, enableResale) {
        state.enableResale = enableResale;
    },
    enableWatch(state, enableWatch) {
        state.enableWatch = enableWatch;
    },
    enableNewCart(state, enableNewCart) {
        state.enableNewCart = enableNewCart;
    },
    liveBanner(state, liveBanner) {
        state.liveBanner = liveBanner;
    },
    askUsersForInstagramOnSignup(state, askUsersForInstagramOnSignup) {
        state.askUsersForInstagramOnSignup = askUsersForInstagramOnSignup;
    },
    profileImageRequired(state, profileImageRequired) {
        state.profileImageRequired = profileImageRequired;
    },
    isPhoneNumberRequired(state, isPhoneNumberRequired) {
        state.isPhoneNumberRequired = isPhoneNumberRequired;
    },
    userPhoneShown(state, userPhoneShown) {
        state.userPhoneShown = userPhoneShown;
    },
    profileImageShown(state, profileImageShown) {
        state.profileImageShown = profileImageShown;
    },
    userAddressShown(state, userAddressShown) {
        state.userAddressShown = userAddressShown;
    },
    userAddressRequired(state, userAddressRequired) {
        state.userAddressRequired = userAddressRequired;
    },
    userInstagramRequired(state, userInstagramRequired) {
        state.userInstagramRequired = userInstagramRequired;
    },
    userGenderShown(state, userGenderShown) {
        state.userGenderShown = userGenderShown;
    },
    userGenderRequired(state, userGenderRequired) {
        state.userGenderRequired = userGenderRequired;
    },
    userDOBShown(state, userDOBShown) {
        state.userDOBShown = userDOBShown;
    },
    userDOBRequired(state, userDOBRequired) {
        state.userDOBRequired = userDOBRequired;
    },
    userCountryShown(state, userCountryShown) {
        state.userCountryShown = userCountryShown;
    },
    userCountryRequired(state, userCountryRequired) {
        state.userCountryRequired = userCountryRequired;
    },
    newOrdersActive(state, newOrdersActive) {
        state.newOrdersActive = newOrdersActive;
    },
    enableAdditionalCommentsOnOrderItem(state, enableAdditionalCommentsOnOrderItem) {
        state.enableAdditionalCommentsOnOrderItem = enableAdditionalCommentsOnOrderItem;
    },
    facebookPixelId(state, facebookPixelId) {
        // if facebookPixelId is set and received for the first time, call init code
        if (facebookPixelId && state.facebookPixelId !== facebookPixelId && window.fbq) {
            window.fbq('init', facebookPixelId);
            window.fbq('track', 'PageView');
        }
        state.facebookPixelId = facebookPixelId;
    },
    cartMaxItems(state, cartMaxItems) {
        state.cartMaxItems = cartMaxItems;
    },
    useEventCatalog(state, useEventCatalog) {
        state.useEventCatalog = useEventCatalog;
    },
    enablePackageOwners(state, enablePackageOwners) {
        state.enablePackageOwners = enablePackageOwners;
    },
    allowSectionLeaderConnectAccount(state, allowSectionLeaderConnectAccount) {
        state.allowSectionLeaderConnectAccount = allowSectionLeaderConnectAccount;
    },
    paymentProcessor(state, paymentProcessor) {
        state.paymentProcessor = paymentProcessor;
    },
    cartExpiryTime(state, cartExpiryTime) {
        state.cartExpiryTime = cartExpiryTime;
    },
    dateOfBirthFormat(state, dateOfBirthFormat) {
        state.dateOfBirthFormat = dateOfBirthFormat;
    },
    minCustomerAge(state, minCustomerAge) {
        state.minCustomerAge = minCustomerAge;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
