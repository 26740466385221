import moment from 'moment';
const Parse = globalThis.Parse;
export const Season = Parse.Object.extend("Season", {
    initialize: function () {
    },
    name: function () { return this.get("name"); },
    desc: function () { return this.get("desc"); },
    //createdAt is a top level member
    isSelling: function () { return this.get("isSelling"); },
    eventDate: function () { return moment(this.get("eventDate")).format("YYYY-MM-DD"); },
    salesStartDate: function () { return moment(this.get("salesStartDate")).format("YYYY-MM-DD"); },
    startDate: function () { return moment(this.get('startDateTime')).format("D MMM YYYY"); },
    startTime: function () { return moment(this.get('startDateTime')).format("h:mm A"); },
    useMeals: function () { return this.get("useMeals"); },
    mealsArray: function () {
        const list = this.get("mealsArray");
        if (list && list.length > 0) {
            return list;
        }
        return ["Meat", "Vegetarian"];
    },
    mealsAsKeyValue: function () { return paidutils.toKeyValueArray(this.mealsArray()); },
    getMealName: function (mealKey) {
        let name = "Unknown";
        const meal = parseInt(mealKey);
        const mealsArray = this.mealsArray();
        if (mealsArray.length > meal) {
            name = mealsArray[meal];
        }
        return name;
    },
    useReferrals: function () { return this.get("useReferrals"); },
    validateReferralCode: function (code) {
        return this.get("referralCodes").includes(code);
    }
});
