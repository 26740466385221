<template>
  <nav class="main-nav navbar navbar-expand-md navbar-light bg-light">
    <span class="d-none d-sm-block text-muted">{{ bandName }}</span>

    <div class="collapse navbar-collapse navbar-expand justify-content-end" id="navbarNavDropdown">
      <ul class="navbar-nav ml-auto mt-lg-0">
        <li v-if="useEventCatalog" class="nav-item" id="nav-events">
          <router-link class="nav-link" :to="`/events`"><ion-icon class="ion-icon-btn-align"
              name="calendar-outline"></ion-icon> Events</router-link>
        </li>
        <li v-else class="nav-item" id="nav-catalog">
          <router-link class="nav-link" :to="`/catalog`"><ion-icon class="ion-icon-btn-align"
              name="calendar-outline"></ion-icon> Shop</router-link>
        </li>
        <li v-if="enableWatch" class="nav-item" id="nav-watch">
          <router-link class="nav-link" :to="`/watch`"><ion-icon class="ion-icon-btn-align"
              name="videocam-outline"></ion-icon> Watch</router-link>
        </li>
        <li v-if="enableResale" class="nav-item" id="nav-marketplace">
          <router-link class="nav-link" :to="`/marketplace`"><ion-icon class="ion-icon-btn-align"
              name="storefront-outline"></ion-icon> Marketplace</router-link>
        </li>
        <li class="nav-item" id="nav-signin">
          <router-link class="nav-link" to="/signin"><ion-icon class="ion-icon-btn-align"
              name="log-in-outline"></ion-icon> Sign in</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "LoggedOutNavBar",
  created () {
    this.$store.dispatch('settings/getAppSettings')
  },
  computed: {
    ...mapGetters('settings', ['bandName', 'enableWatch', 'enableResale', 'useEventCatalog'])
  }
}
</script>
<style scoped>

.main-nav{
  padding: 0.5rem 1rem !important;
}  
</style>