<template>
<div>
  <LoggedOutNavBar :activePage="activePage" />
  <div id="main" class="container">
    <slot></slot>
  </div>
</div>
</template>

<script>
import LoggedOutNavBar from "../components/LoggedOutNavBar";

export default {
  name: "LoggedOut",
  components: {
    LoggedOutNavBar
  },
  props: {
    activePage: String
  }
};
</script>

<style>
</style>
