<template>
  <div class="">
    <nav class="main-nav navbar navbar-expand-md navbar-light bg-light">
      <a class="navbar-brand no-href" @click="goBack"><i class="back-arrow fa fa-chevron-left"></i></a>
      <span class="d-none d-sm-block ml-md-2 text-muted">{{ bandName }}</span>

      <div class="collapse navbar-collapse navbar-expand justify-content-end" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto">
          <li v-if="useEventCatalog" class="nav-item" :class="{ 'active': isNavActive() }" id="nav-events">
            <router-link class="nav-link" to="/events"><ion-icon class="ion-icon-btn-align"
                name="calendar-outline"></ion-icon>
              Events</router-link>
          </li>
          <li v-else class="nav-item" :class="{ 'active': isNavActive() }" id="nav-ordermanager">
            <router-link class="nav-link" to="/events">Shop</router-link>
          </li>
          <li v-if="enableNewCart" class="nav-item" id="nav-cart" @click="openCart" v-b-toggle.sidebar-no-header
            aria-controls="sidebar-no-header" aria-expanded="true">
            <a class="nav-link" id="cart"><ion-icon class="ion-icon-btn-align" name="cart-outline"></ion-icon>
              Cart</a>
          </li>

          <li class="nav-item dropdown" role="menu">
            <b-dropdown variant="link" toggle-class="text-decoration-none" offset="-115">
              <template #button-content>
                <span class="override-btn-size">
                  <ion-icon class="ion-icon-btn-align" name="apps"></ion-icon> More
                </span>
              </template>
              <span style="font-size: 1.2rem;">
                <b-dropdown-item to="/orders" class="my-1">
                  <ion-icon class="ion-icon-btn-align" name="qr-code-outline"></ion-icon> My Orders
                </b-dropdown-item>
                <b-dropdown-item to="/marketplace" class="my-1" v-if="enableResale">
                  <ion-icon class="ion-icon-btn-align" name="storefront-outline"></ion-icon> Marketplace
                </b-dropdown-item>
                <b-dropdown-item to="/authorized-items" class="my-1">
                  <ion-icon class="ion-icon-btn-align" name="id-card-outline"></ion-icon> Authorized Items
                </b-dropdown-item>
                <b-dropdown-item to="/watch" class="my-1" v-if="enableWatch">
                  <ion-icon class="ion-icon-btn-align" name="videocam-outline"></ion-icon> Watch
                </b-dropdown-item>
                <b-dropdown-item to="/codes" class="my-1" v-if="showRegistrationCodesNav"> My Registration Codes
                </b-dropdown-item>
                <b-dropdown-item to="/committeelinks" class="my-1" v-if="showCommitteeLinks"> Committee Links
                </b-dropdown-item>

                <div class="dropdown-divider"></div>

                <b-dropdown-item to="/profile" class="my-1">
                  <ion-icon class="ion-icon-btn-align" name="person-circle-outline"></ion-icon> Profile
                </b-dropdown-item>
                <b-dropdown-item-button variant="danger" class="my-1" @click="logout">
                  <ion-icon class="ion-icon-btn-align" name="log-out-outline"></ion-icon> Sign Out
                </b-dropdown-item-button>
              </span>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { analyticsTrack } from '../helpers/utils';

export default {
  name: "Nav-Bar",
  props: {
    bandName: String,
    useEventCatalog: Boolean,
    enableNewCart: Boolean,
    showOrders: Boolean,
    enableResale: Boolean,
    showApplications: Boolean,
    enableWatch: Boolean,
    showRegistrationCodesNav: Boolean,
    showApplicationsNav: Boolean,
    showCommitteeLinks: Boolean,
  },
  data () {
    return {
      isNavItemSelected: 'events',
    }
  },
  methods: {
    isNavActive () {
      if (this.$route.path === '/events') {
        this.isNavItemSelected = 'events';
        return true;
      } else {
        this.isNavItemSelected = '';
        return false;
      }
    },
    isMenuItemActive (path) {
      if (this.$route.path === path) {
        return true;
      } else {
        return false;
      }
    },
    openCart () {
    },
    async goBack (e) {
      e.preventDefault();
      const pageName = window.location.hash.split('?')
      const isCheckoutPage = pageName[0] && (pageName[0] === 'checkoutsuccess' || pageName[0] === 'checkoutcancel')

      if (isCheckoutPage) {
        await this.$router.replace({ path: 'order-costume' });
      } else {
        await this.$router.back();
      }
    },
    logout () {
      Parse.User.logOut().then(function () {
        window.bugsnagClient.user = {};
        this.$posthog.reset();
      });
      analyticsTrack('Log Out');
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.cp {
  cursor: pointer;
}

.main-nav {
  padding: 0.5rem 1rem !important;
}

.override-btn-size,
.btn-link {
  font-size: 19.2px;
  color: #00000080 !important;
}

::v-deep .btn:focus {
  box-shadow: none !important;
}
</style>