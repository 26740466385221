<template>
  <div id="app">
    <LoggedIn v-if="isLoggedIn">
      <router-view v-slot="{ Component }" :key="$route.path">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </LoggedIn>
    <LoggedOut v-else>
      <router-view v-slot="{ Component }" :key="$route.path">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </LoggedOut>
  </div>
</template>

<script>
import LoggedOut from './layouts/LoggedOut.vue';
import LoggedIn from './layouts/LoggedIn.vue';
import { mapGetters } from 'vuex';
import { setMetaTags } from './helpers/utils';

export default {
  name: "App",
  components: {
    LoggedOut,
    LoggedIn,
  },
  data () {
    return {
      transitionName: 'slide-right',
    }
  },
  created () {
    setMetaTags({ title: this.bandName, description: 'Playmas is a powerful carnival management platform designed for mas camps and masqueraders.' });
    this.$posthog.group('server', window.Target.app_id);
    const redirectURL = this.$localStorage.get('signinRedirect') || this.$localStorage.get('signupRedirect');
    if (redirectURL) {
      this.$router.push(redirectURL);
      this.$localStorage.remove('signinRedirect');
      this.$localStorage.remove('signupRedirect');
    }
  },
  computed: {
    ...mapGetters('settings', ['bandName']),
    isLoggedIn () {
      return !!Parse.User.current();
    },
  }
}

</script>

<style>
/* Your global styles here */

.fade-enter-active,
.fade-leave-active {
  transition: all .3s ease
}

.fade-enter,
.fade-leave-active {
  opacity: 0.3
}
</style>
