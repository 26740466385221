import { createRouter, createWebHistory } from 'vue-router';
import { setAnalyticsPage } from '../helpers/utils';
import { captureAttribution } from '../helpers/packages';
import { nextTick } from 'vue';
const routes = [
    {
        path: '/profile', name: 'Profile', component: () => import('../components/Profile.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/edit-profile', name: 'EditProfile', component: () => import('../components/EditProfile.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/signin', name: 'Login', component: () => import('../pages/Login.vue'),
        props: route => ({ redirect: route.query.redirect })
    },
    {
        path: '/signup', name: 'Signup', component: () => import('../pages/SignUp.vue'),
        props: route => ({ redirect: route.query.redirect })
    },
    {
        path: '/authorized-items', name: 'AuthorizedItems', component: () => import('../pages/AuthorizedItems.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    { path: '/marketplace', name: 'MarketPlace', component: () => import('../pages/Marketplace.vue') },
    { path: '/marketplace/confirmation', name: 'ResaleThankYouPage', component: () => import('../pages/ResaleThankYouPage.vue') },
    { path: '/marketplace/order/:orderId', name: 'MarketPlaceDetail', component: () => import('../pages/MarketplaceDetail.vue'), props: true },
    {
        path: '/marketplace/purchase/:orderId', name: 'ResalePurchase', component: () => import('../pages/ResalePurchase.vue'), props: true,
        beforeEnter: [checkUserAuthentication],
    },
    { path: '/events/:eventslug', name: 'EventDetail', component: () => import('../pages/Catalog.vue'), props: true },
    { path: '/sections/:sectionslug', name: 'SectionDetail', component: () => import('../pages/SectionCatalog.vue'), props: true },
    { path: '/events', name: 'Events', component: () => import('../pages/Events.vue') },
    {
        path: '/orders', name: 'Orders', component: () => import('../pages/Orders.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/order/:orderId', name: 'Order', component: () => import('../pages/Order.vue'), props: true,
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/checkout', name: 'Checkout', component: () => import('../pages/Checkout.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/checkoutsuccess', name: 'ThankYouPage', component: () => import('../pages/ThankYouPage.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/checkoutcancel', name: 'CheckoutCancel', component: () => import('../pages/CheckoutCancel.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/tos', name: 'TermsOfService', component: () => import('../pages/TermsOfService.vue'),
        props: route => ({ redirect: route.query.redirect }),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/committee-links', name: 'CommitteeLinks', component: () => import('../components/CommitteeLinks.vue'),
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/p/:packageId',
        name: 'PackageDetail',
        component: () => import('../pages/DetailPage.vue'), props: true,
        beforeEnter: [checkUserAuthentication],
    },
    {
        path: '/onboard', name: 'Onboard', component: () => import('../pages/Onboard.vue'),
        props: route => ({ redirect: route.query.redirect }),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        redirect: (to) => {
            console.log(to);
            return '/events';
        }
    },
    {
        path: '/',
        name: 'Home',
        redirect: () => {
            return '/events';
        },
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}
router.beforeEach((to, from, next) => {
    if (Parse.User.current() && (to.path === '/signin' || to.name === 'LoginRedirect' || to.path === '/signup')) {
        next({ name: 'Events', query: from.query });
    }
    else if (!hasQueryParams(to) && hasQueryParams(from)) {
        if (from.path === '/onboard') {
            next();
        }
        else {
            next({ path: to.path, query: from.query });
        }
    }
    else {
        next();
    }
});
router.afterEach((to, _from, failure) => {
    if (failure) {
        console.warn('navigation failure', failure);
    }
    else {
        nextTick(() => {
            global.Vue.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
        });
    }
    const { orderId, eventslug, packageId } = to.params;
    setAnalyticsPage(to.path, orderId || eventslug || packageId);
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // You can use 'auto' instead of 'smooth' for an instant scroll
    });
    paidutils.refreshConfig();
    paidutils.refreshAppSettings();
    captureAttribution();
});
function checkUserAuthentication(to, from, next) {
    if (Parse.User.current()) {
        next();
    }
    else {
        next({ name: 'Login', query: { redirect: to.path } });
    }
}
export default router;
