import moment from 'moment';
const Parse = globalThis.Parse;
export const OrderMeta = Parse.Object.extend("Order", {
    initialize: function () {
    },
    packageCost: function () {
        // console.log("depreciated");
        const packageCost = this.get("totalCost") - this.get("addon");
        return paidutils.formatMoney(packageCost, this.currency());
    },
    marketplaceCost: function () {
        // console.log("depreciated");
        return this.get("marketplaceCost") ? paidutils.formatMoney(this.get("marketplaceCost"), this.currency()) : paidutils.formatMoney(0, this.currency());
    },
    addOnCost: function () {
        // console.log("depreciated");
        return paidutils.formatMoney(this.get("addon"), this.currency());
    },
    totalCost: function () {
        // console.log("depreciated");
        return paidutils.formatMoney(this.get("totalCost"), this.currency());
    },
    totalPaid: function () {
        // console.log("depreciated");
        // we need to sum marketplace cost with total paid for complete paid amount
        const marketplaceCost = this.get("marketplaceCost") ? this.get("marketplaceCost") : 0;
        const total = this.get("amountPaid") + marketplaceCost;
        return paidutils.formatMoney(total, this.currency());
    },
    balanceDue: function () {
        // console.log("depreciated");
        const balance = Math.max(0, this.get("totalCost") - this.get("amountPaid"));
        return paidutils.formatMoney(balance, this.currency());
    },
    hasCompletedPayment: function () {
        // console.log("depreciated");
        const balance = this.get("totalCost") - this.get("amountPaid");
        return balance <= 0;
    },
    orderDateTime: function () {
        // console.log("depreciated");
        return moment(this.createdAt).format("YYYY/DD/MM hh:mmA");
    },
    prettyDate: function () {
        // console.log("depreciated");
        return moment(this.createdAt).format("D MMM YYYY");
    },
    prettyTime: function () {
        // console.log("depreciated");
        return moment(this.createdAt).format("h:mmA");
    },
    fromNowDate: function () {
        // console.log("depreciated");
        return moment(this.createdAt).fromNow();
    },
    status: function () {
        // console.log("depreciated");
        return this.get("state");
    },
    user: function () {
        // console.log("depreciated");
        return new globalThis.Website.Models.User(this.get("user"));
    },
    presentation: function () {
        // console.log("depreciated");
        return this.get("section").get("presentation").get("name");
    },
    showMarketplaceDetails: function () {
        // console.log("depreciated");
        const marketplaceActive = Parse.Config.current().get("marketplaceActive");
        const hasMarketplaceCost = (this.get("marketplaceCost") > 0);
        return marketplaceActive || hasMarketplaceCost;
    },
    collectionUsers: function () {
        // console.log("depreciated");
        const collection_users = [];
        collection_users.push(this.get("user"));
        if (this.get("collection_user")) {
            collection_users.push(this.get("collection_user"));
        }
        return collection_users;
    },
    defaults: {},
    validate: function (attrs, options) {
    },
    note: function () {
        // console.log("depreciated");
        return this.get("note");
    },
    currency: function () {
        return this.get('currency') ? this.get('currency') : paidutils.getCurrencyCode();
    },
    isCollected: function () {
        return this.get('collectionStatus') === 'collected' || this.get('collectionStatus') === 'COLLECTED';
    },
    isPartiallyCollected: function () {
        return this.get('collectionStatus') == 'partially_collected' || this.get('collectionStatus') === 'PARTIALLY_COLLECTED';
    },
});
