import { getNoImageURL } from '../helpers/utils';
const Parse = globalThis.Parse;
export const Package = Parse.Object.extend("Package", {
    initialize: function () {
    },
    defaults: {
        name: "",
        titleImage: null,
        cost: 0.0,
        section: null
    },
    cost: function () { return this.get("cost"); },
    formattedCost: function () { return paidutils.formatMoney(this.get("cost")); },
    image: function () {
        if (this.get("thumbnail")) {
            return this.get("thumbnail").url();
        }
        return getNoImageURL();
    },
    getGenderOptions: function () {
        return [
            "Male & Female",
            "Male",
            "Female"
        ];
    },
    getGenderName: function (gender) {
        const genders = this.getGenderOptions();
        gender = parseInt(gender);
        if (gender >= 0 && gender < genders.length) {
            return genders[gender];
        }
        return "Unknown";
    },
    genderName: function () {
        return this.getGenderName(this.get("gender"));
    },
    name: function () {
        return this.get("name");
    },
    soldout: function () {
        const override = this.get("soldOutOverride");
        // qtyAvailable == 0 means unlimited
        const soldout = this.hasQtyLimits() ? this.get("qtySold") >= this.get("qtyAvailable") : false;
        return override || soldout;
    },
    limitedQtyLeft: function () {
        let remainingRatio = this.hasQtyLimits() ? parseFloat((parseInt(this.get("qtySold")) / parseInt(this.get("qtyAvailable")))) : 0;
        remainingRatio = 1 - remainingRatio;
        const fewRemainingThresholdPct = .20;
        return remainingRatio <= fewRemainingThresholdPct;
    },
    hasQtyLimits: function () {
        return this.get("qtyAvailable") != 0;
    },
    section: function () {
        return this.get("section");
    },
    parse: function (response, options) {
        return response;
    }
});
