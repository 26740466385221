import Parse from 'parse';
import { Target } from '../target';
import { isSupportedBrowser, showModal } from './utils';
window.Target = Target;
if (!isSupportedBrowser()) {
    showModal("Browser Issue", 'Browser not supported.');
}
// if accessing from playmas.app and app_id is not specified
if (!window.Target.app_id) {
    if (window.location.hostname.toLowerCase() === "playmas.app") {
        // redirect to marketing website
        window.location.replace("https://www.playmas.app");
    }
    else {
        console.log("%cPlace your APP ID as the subdomain", "font-family:sans-serif; font-size: 20px");
    }
    showModal("URL issue", "Place your APP ID as the subdomain");
}
else {
    const serverURL = window.Target.server + window.Target.app_id;
    const appId = window.Target.app_id;
    const jsKey = window.Target.js_key;
    Parse.initialize(appId, jsKey);
    Parse.serverURL = serverURL;
    global.Parse = Parse;
}
