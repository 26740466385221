import _ from 'lodash';
const Parse = globalThis.Parse;
export const Order = Parse.Object.extend('Order', {
    initialize: function (parseObject) {
        this.data = parseObject;
        this.order = new globalThis.Website.Models.OrderMeta(parseObject.order);
        this.payments = parseObject.payments;
        if (Object.keys(parseObject.orderItems || {}).length > 0 && parseObject.orderItems.constructor === Object) {
            //nothing here for now
            this.orderItems = parseObject._orderItems;
            this.stitch();
        }
        else {
            this.package = _.flatten([parseObject.package]);
            this.section = _.flatten([parseObject.section]);
            this.presentation = _.map(this.section, function (item) { return item ? item.get("presentation") : ''; });
            this.instances = _.map(parseObject.instances, function (inst) { return new globalThis.Website.Models.ItemInstance(inst); });
        }
    },
    stitch: function () {
        const self = this;
        _.each(this.orderItems, function (item) {
            const match = _.find(self.data.orderItems, function (dataItem, key) { return key == item.id; });
            if (match) {
                item.stitch(match);
            }
            else {
                console.warn("not stitching data for orderItem " + item.id);
            }
        });
    },
    conveniencefee: function () {
        let total = 0.0;
        _.each(this.payments, function (item) {
            const fee = item.get("fee") || 0;
            total += parseFloat(fee);
        });
        return paidutils.formatMoney(total);
    },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    },
});
