var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import DataService from '../services/DataService';
const ds = DataService.getInstance();
export default class CartExpirationTimer {
    constructor() {
        this.cartId = '';
        this.countdownTimer = 0;
        this.intervalId = null;
        this.tickCallbacks = {};
    }
    static getInstance() {
        if (!CartExpirationTimer.instance) {
            CartExpirationTimer.instance = new CartExpirationTimer();
        }
        return CartExpirationTimer.instance;
    }
    isRunning() {
        return this.intervalId !== null;
    }
    isActive() {
        return this.cartId !== '' && this.countdownTimer > 0;
    }
    expired() {
        return this.countdownTimer <= 0;
    }
    getTimeRemaining() {
        return this.countdownTimer;
    }
    formattedCountdown() {
        const duration = moment.duration(this.countdownTimer);
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
    startCountdown(cartId, expireTime) {
        if (this.isRunning()) {
            console.warn('Timer already running');
            return;
        }
        this.cartId = cartId;
        const currentTime = moment();
        const formattedExpiryTime = moment(expireTime);
        const timeDifference = moment.duration(formattedExpiryTime.diff(currentTime));
        this.countdownTimer = timeDifference.asMilliseconds();
        if (this.countdownTimer <= 0) {
            console.warn('No time left or negative time difference');
            return;
        }
        // Bind `this` to the `timerTick` method
        this.intervalId = setInterval(() => this.timerTick(), 1000);
    }
    subscribe(componentName, tickCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            this.tickCallbacks[componentName] = tickCallback;
            tickCallback({
                countdown: this.countdownTimer,
                formatted: this.formattedCountdown(),
            });
        });
    }
    reset(cartId) {
        return __awaiter(this, void 0, void 0, function* () {
            this.cartId = cartId;
            this.countdownTimer = 0;
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
            this.tickCallbacks = {};
            if (this.cartId) {
                //Piyush: Commenting out this line because somehow this is executing for bands who have
                // not enabled the cartExpiry also. We should investigate it and enable it after that
                // await ds.destroyCart(this.cartId);
                this.cartId = '';
            }
        });
    }
    timerTick() {
        this.countdownTimer -= 1000;
        this.sendTicks();
        if (this.countdownTimer <= 0) {
            this.reset(this.cartId);
        }
    }
    sendTicks() {
        for (const key in this.tickCallbacks) {
            this.tickCallbacks[key]({
                countdown: this.countdownTimer,
                formatted: this.formattedCountdown(),
            });
        }
    }
}
