const Parse = globalThis.Parse;
export const OptionMembership = Parse.Object.extend("OptionMembership", {
    url: '',
    initialize: function () {
    },
    defaults: {
        name: "",
        itemTemplate: null,
        option: null,
    },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    }
});
