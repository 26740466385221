var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
const Parse = globalThis.Parse;
const globalRef = global;
let _cachedSettings;
const paidutilsObject = {
    replaceMergeTags: function (text, settings) {
        const user = Parse.User.current() || null;
        const mergeTagToValue = {
            USER_FIRST_NAME: user ? user.get("firstName") : "",
            USER_LAST_NAME: user ? user.get("lastName") : "",
            USER_EMAIL: user ? user.get("email") : "",
            BAND_NAME: settings.band_name,
        };
        const getMergeTagValue = function (tagName) {
            const key = tagName.replace(/[*]/g, "");
            // eslint-disable-next-line no-prototype-builtins
            const value = mergeTagToValue.hasOwnProperty(key) ? mergeTagToValue[key] : "";
            return value;
        };
        const mergeTags = text.match(/\*\s*[^*]*?\s*\*/g); //https://regex101.com/r/QvTS6q/2
        const mergeValue = _.map(mergeTags, function (tag) { return getMergeTagValue(tag); });
        _.each(mergeTags, function (tag, index) {
            text = text.replace(tag, mergeValue[index]);
        });
        return text;
    },
    getWebsiteTextMapFromSettings: function (settings, resolveMergeTags) {
        // defaults resolveMergeTags to true
        resolveMergeTags = typeof resolveMergeTags !== 'undefined' ? resolveMergeTags : true;
        const settingKeys = Array.from(Object.keys(settings));
        const websiteTextKeys = _.filter(settingKeys, function (key) { return key.startsWith("website_text_"); });
        const website_text = [];
        _.each(websiteTextKeys, function (key) {
            const value = resolveMergeTags ? paidutils.replaceMergeTags(settings[key], settings) : settings[key];
            website_text.push({
                key: key.replace("website_text_", ""),
                value: value
            });
        });
        return website_text;
    },
    getCurrencyCode: function () {
        return Parse.Config.current() && Parse.Config.current().get("stripeCurrency");
    },
    getCurrencySymbol: function () {
        return Parse.Config.current() && Parse.Config.current().get("stripeCurrencySymbol");
    },
    getBandName: function () {
        return Parse.Config.current() && Parse.Config.current().get("bandName");
    },
    getPortalURL: function () {
        return Parse.Config.current() && Parse.Config.current().get("portalURL");
    },
    getNoImageURL: function () {
        return "/images/no-image.jpg";
    },
    toKeyValueArray: function (obj) {
        const array = [];
        if (Array.isArray(obj)) {
            _.each(obj, function (item, i) {
                array.push({
                    key: i,
                    value: item
                });
            });
        }
        else {
            for (const prop in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                    array.push({
                        key: prop,
                        value: obj[prop]
                    });
                }
            }
        }
        return array;
    },
    getPaymentOptions: function () {
        return [
            { value: 0, name: "Online Payment" },
            { value: 1, name: "Linx" },
            { value: 2, name: "Cash" },
            { value: 3, name: "Credit Card" },
            { value: 4, name: "Discount", role: "admin" },
            { value: 5, name: "Refund", role: "master" }
        ];
    },
    getApplyablePaymentOptions: function () {
        const paymentOptions = globalThis.getPaymentOptions();
        return paymentOptions.splice(1);
    },
    getPaymentMethod: function (option) {
        const paymentOptions = globalThis.getPaymentOptions();
        option = parseInt(option);
        const paymentMethod = _.find(paymentOptions, { value: option });
        if (!paymentMethod) {
            return "Unknown Payment Method";
        }
        return paymentMethod.name;
    },
    // Fetches the config at most once every 12 hours per app runtime
    refreshConfig: function () {
        let lastFetchedDate;
        const configRefreshInterval = 12 * 60 * 60 * 1000;
        return function () {
            const currentDate = new Date();
            if (lastFetchedDate === undefined ||
                currentDate.getTime() - lastFetchedDate.getTime() > configRefreshInterval) {
                Parse.Config.get();
                lastFetchedDate = currentDate;
            }
        };
    }(),
    // will get fresh app settings every 30 seconds when called
    refreshAppSettings: function () {
        return _.throttle(function () {
            Parse.Cloud.run("getAppSettings").then(function (settings) {
                _cachedSettings = settings;
            });
        }, 30 * 1000, { leading: false });
    }(),
    getAppSettings: function () {
        let _promise;
        return function () {
            paidutilsObject.refreshAppSettings();
            if (_cachedSettings) {
                return Promise.resolve(_cachedSettings);
            }
            else if (_promise) {
                return _promise;
            }
            else {
                _promise = Parse.Cloud.run("getAppSettings").then(function (settings) {
                    _cachedSettings = settings;
                    return Promise.resolve(settings);
                });
                return _promise;
            }
        };
    }(),
    getUserGenderOptions: function () {
        return [
            "Male",
            "Female"
        ];
    },
    getUserGenderName: function (gender) {
        const genders = paidutilsObject.getUserGenderOptions();
        gender = parseInt(gender);
        if (gender >= 0 && gender < genders.length) {
            return genders[gender];
        }
        return "Unknown";
    },
    getPackageGenderOptions: function () {
        return [
            "Male & Female",
            "Male",
            "Female"
        ];
    },
    getPackageGenderName: function (gender) {
        const genders = paidutilsObject.getPackageGenderOptions();
        gender = parseInt(gender);
        if (gender >= 0 && gender < genders.length) {
            return genders[gender];
        }
        return "Unknown";
    },
    formatMoney: function (amount, currency = 'USD') {
        currency = currency ? currency : this.getCurrencyCode(); // defaults to global currency
        const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
        if (!formatted) {
            return "N/A";
        }
        return formatted;
    },
    guid: function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    },
    // currently only supports JPG images
    uploadImage: function (imageInputField) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.validateImage(imageInputField, {});
            const file = imageInputField.files[0];
            const name = this.guid() + ".jpg";
            const parseFile = new Parse.File(name, file, "jpg");
            return parseFile.save();
        });
    },
    generateImagePreview: function (input) {
        const promise = new Promise((resolve, reject) => {
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    resolve(e.target.result);
                };
                reader.readAsDataURL(input.files[0]);
            }
            else {
                return reject("No File");
            }
        });
        return promise;
    },
    resizeImage: function (image, maxWidth, maxHeight) {
        const promise = new Promise((resolve) => {
            const file = image.files[0];
            const imgLoader = new Image();
            imgLoader.onload = function () {
                // Desired size
                const max_width = maxWidth || 1000;
                const max_height = maxHeight || 1000;
                // Get image dimensions
                const original_width = imgLoader.width;
                const original_height = imgLoader.height;
                let new_height, new_width;
                // Calculate final dimensions
                if (original_width > original_height) {
                    if (original_width > max_width) {
                        const ratio = max_width / original_width;
                        new_height = Math.round(original_height * ratio);
                        new_width = max_width;
                    }
                    else {
                        new_height = original_height;
                        new_width = original_width;
                    }
                }
                else {
                    if (original_height > max_height) {
                        const ratio = max_height / original_height;
                        new_width = Math.round(original_width * ratio);
                        new_height = max_height;
                    }
                    else {
                        new_height = original_height;
                        new_width = original_width;
                    }
                }
                // Resizing function
                function resize_step(image, new_width, new_height) {
                    // Create new canvas
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    // Get incremental image size
                    const half_width = Math.round(image.width / 2);
                    const half_height = Math.round(image.height / 2);
                    if (half_width > new_width) {
                        // Resize image	by 50%
                        canvas.width = half_width;
                        canvas.height = half_height;
                        ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, 0, 0, half_width, half_height);
                        // Resize again
                        return resize_step(canvas, new_width, new_height);
                    }
                    else {
                        // Final Resize of Image
                        canvas.width = new_width;
                        canvas.height = new_height;
                        ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, 0, 0, new_width, new_height);
                        // Return resized image
                        return canvas;
                    }
                }
                // Fire resizing function
                const resized_image = resize_step(imgLoader, new_width, new_height);
                return resolve(resized_image);
            };
            // Load image
            const _URL = window.URL || window.webkitURL;
            imgLoader.setAttribute('crossOrigin', 'anonymous');
            imgLoader.src = _URL.createObjectURL(file);
        });
        return promise;
    },
    validateImage: function (image, options) {
        const defaults = {
            minWidth: Parse.Config.current().get("imageMinWidthHeight"),
            minHeight: Parse.Config.current().get("imageMinWidthHeight"),
            maxWidth: Parse.Config.current().get("imageMaxWidthHeight"),
            maxHeight: Parse.Config.current().get("imageMaxWidthHeight")
        };
        if (!options)
            options = {};
        const appliedOptions = {
            minWidth: options.minWidth || defaults.minWidth,
            minHeight: options.minHeight || defaults.minHeight,
            maxWidth: options.maxWidth || defaults.maxWidth,
            maxHeight: options.maxHeight || defaults.maxHeight
        };
        const promise = new Promise((resolve, reject) => {
            const file = image.files[0];
            if (file) {
                const url = file.name;
                const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
                if (image.files &&
                    image.files[0] &&
                    (ext == "png" || ext == "jpeg" || ext == "jpg")) {
                    const _URL = window.URL || window.webkitURL;
                    const newImage = new Image();
                    newImage.onload = function () {
                        if (this.width >= appliedOptions.minWidth &&
                            this.width <= appliedOptions.maxWidth &&
                            this.height >= appliedOptions.minHeight &&
                            this.height <= appliedOptions.maxHeight) {
                            return resolve(this.src);
                        }
                        else {
                            return reject({ code: "901", message: "Image height and width must be between " + appliedOptions.minWidth + "x" + appliedOptions.minHeight + " to " + appliedOptions.maxWidth + "x" + appliedOptions.maxHeight + "." });
                        }
                    };
                    newImage.src = _URL.createObjectURL(file);
                }
                else {
                    return reject({ code: "902", message: "Image is not a valid file type." });
                }
            }
            else {
                return reject({ code: "903", message: "No image selected." });
            }
        });
        return promise;
    },
    getDateRange: function (models) {
        const range = { "start": null, "end": null };
        _.each(models, function (model) {
            const day = moment(model.createdAt);
            range.start = range.start ? moment.min(day, range.start) : day;
            range.end = range.end ? moment.max(day, range.end) : day;
        });
        return range;
    },
    quantize: function (filters, models) {
        const buckets = {};
        if (models && models.length) {
            const range = filters ? filters : this.getDateRange(models);
            const date = moment(range.start);
            for (; date.isBefore(range.end); date.add(1, 'd')) {
                buckets[date.format("MMM Do")] = [];
            }
            _.each(models, function (order) {
                const day = moment(order.createdAt).format("MMM Do");
                if (day in buckets) {
                    buckets[day].push(order);
                }
            });
        }
        return buckets;
    },
    abbrNum: function (number, decPlaces, money) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);
        // any money less than 1000, we do not abbreviate
        if (money && number < 1000) {
            return this.formatMoney(number, 'USD');
        }
        // Enumerate number abbreviations
        const abbrev = ["k", "m", "b", "t"];
        // Go through the array backwards, so we do the largest first
        for (let i = abbrev.length - 1; i >= 0; i--) {
            // Convert array index to "1000", "1000000", etc
            const size = Math.pow(10, (i + 1) * 3);
            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;
                // Handle special case where we round up to the next abbreviation
                if ((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }
                // Add the letter for the abbreviation
                number += abbrev[i];
                // We are done... stop
                break;
            }
        }
        return money ? this.getCurrencySymbol() + number : number;
    },
    canOrderBeMutated: function (order) {
        if (!order.get("collection") && !order.get("cancelled")) {
            return true;
        }
        return false;
    },
    isZeroPaymentOrder: function (order) {
        if (order.get("amountPaid") !== undefined) {
            return parseFloat(order.get("amountPaid")) == 0;
        }
        return false;
    },
    isFullyPaidOrder: function (order) {
        if (order.get("amountPaid") && order.get("totalCost")) {
            return parseFloat(order.get("amountPaid")) >= parseFloat(order.get("totalCost"));
        }
        return false;
    },
    canAdminCancelOrder: function (orders) {
        let ordersWithoutPayments = 0;
        const self = this;
        _.each(orders, function (order) {
            if (self.isZeroPaymentOrder(order)) {
                ordersWithoutPayments++;
                return false;
            }
            else {
                return true;
            }
        });
        return ordersWithoutPayments > 0;
    },
    isPossibleToMovePayments: function (orders) {
        if (orders.length > 1) {
            //is it possible to make a move?
            //are there any payments?
            //is there a costume that is not paid off fully
            let ordersWithPayments = 0;
            let ordersFullyPaid = 0;
            const self = this;
            _.each(orders, function (order) {
                if (!self.isZeroPaymentOrder(order)) {
                    ordersWithPayments++;
                    if (self.isFullyPaidOrder(order)) {
                        ordersFullyPaid++;
                    }
                }
            });
            let canMovePayments = (ordersWithPayments > 0);
            canMovePayments = canMovePayments && (ordersFullyPaid != orders.length);
            return canMovePayments;
        }
        return false;
    },
    withSelectedProperties: function (object, properties) {
        if (_.isNil(object)) {
            return {};
        }
        if (_.isEmpty(properties)) {
            if (_.isArray(object)) {
                return _.map(object, function (item) {
                    return (item && item.toJSON) ? item.toJSON() : item;
                });
            }
            else {
                return (object && object.toJSON) ? object.toJSON() : object;
            }
        }
        else {
            if (_.isArray(object)) {
                return _.map(object, function (item) {
                    return _.pick((item && item.toJSON) ? item.toJSON() : item, properties);
                });
            }
            else {
                return _.pick((object && object.toJSON) ? object.toJSON() : object, properties);
            }
        }
    },
    getCountryFromCode: function (code) {
        code = code ? code.toUpperCase() : '';
        const country = _.find(this._list, { code: code });
        return country ? country.name : "Unknown";
    },
    getList: function (selectedCode) {
        let list = [];
        if (selectedCode) {
            _.each(this._list, function (country) {
                if (country.code == selectedCode) {
                    list.push({
                        "name": country.name,
                        "code": country.code,
                        "selected": true
                    });
                }
                else {
                    list.push(country);
                }
            });
        }
        else {
            list = this._list;
        }
        return list;
    },
    _list: [
        { "name": "Trinidad and Tobago", "code": "TT" },
        { "name": "United States", "code": "US" },
        { "name": "United Kingdom", "code": "GB" },
        { "name": "Afghanistan", "code": "AF" },
        { "name": "Åland Islands", "code": "AX" },
        { "name": "Albania", "code": "AL" },
        { "name": "Algeria", "code": "DZ" },
        { "name": "American Samoa", "code": "AS" },
        { "name": "Andorra", "code": "AD" },
        { "name": "Angola", "code": "AO" },
        { "name": "Anguilla", "code": "AI" },
        { "name": "Antarctica", "code": "AQ" },
        { "name": "Antigua and Barbuda", "code": "AG" },
        { "name": "Argentina", "code": "AR" },
        { "name": "Armenia", "code": "AM" },
        { "name": "Aruba", "code": "AW" },
        { "name": "Australia", "code": "AU" },
        { "name": "Austria", "code": "AT" },
        { "name": "Azerbaijan", "code": "AZ" },
        { "name": "Bahamas", "code": "BS" },
        { "name": "Bahrain", "code": "BH" },
        { "name": "Bangladesh", "code": "BD" },
        { "name": "Barbados", "code": "BB" },
        { "name": "Belarus", "code": "BY" },
        { "name": "Belgium", "code": "BE" },
        { "name": "Belize", "code": "BZ" },
        { "name": "Benin", "code": "BJ" },
        { "name": "Bermuda", "code": "BM" },
        { "name": "Bhutan", "code": "BT" },
        { "name": "Bolivia", "code": "BO" },
        { "name": "Bosnia and Herzegovina", "code": "BA" },
        { "name": "Botswana", "code": "BW" },
        { "name": "Bouvet Island", "code": "BV" },
        { "name": "Brazil", "code": "BR" },
        { "name": "British Indian Ocean Territory", "code": "IO" },
        { "name": "Brunei Darussalam", "code": "BN" },
        { "name": "Bulgaria", "code": "BG" },
        { "name": "Burkina Faso", "code": "BF" },
        { "name": "Burundi", "code": "BI" },
        { "name": "Cambodia", "code": "KH" },
        { "name": "Cameroon", "code": "CM" },
        { "name": "Canada", "code": "CA" },
        { "name": "Cape Verde", "code": "CV" },
        { "name": "Cayman Islands", "code": "KY" },
        { "name": "Central African Republic", "code": "CF" },
        { "name": "Chad", "code": "TD" },
        { "name": "Chile", "code": "CL" },
        { "name": "China", "code": "CN" },
        { "name": "Christmas Island", "code": "CX" },
        { "name": "Cocos (Keeling) Islands", "code": "CC" },
        { "name": "Colombia", "code": "CO" },
        { "name": "Comoros", "code": "KM" },
        { "name": "Congo", "code": "CG" },
        { "name": "Congo, The Democratic Republic of the", "code": "CD" },
        { "name": "Cook Islands", "code": "CK" },
        { "name": "Costa Rica", "code": "CR" },
        { "name": "Cote D'Ivoire", "code": "CI" },
        { "name": "Croatia", "code": "HR" },
        { "name": "Cuba", "code": "CU" },
        { "name": "Cyprus", "code": "CY" },
        { "name": "Czech Republic", "code": "CZ" },
        { "name": "Denmark", "code": "DK" },
        { "name": "Djibouti", "code": "DJ" },
        { "name": "Dominica", "code": "DM" },
        { "name": "Dominican Republic", "code": "DO" },
        { "name": "Ecuador", "code": "EC" },
        { "name": "Egypt", "code": "EG" },
        { "name": "El Salvador", "code": "SV" },
        { "name": "Equatorial Guinea", "code": "GQ" },
        { "name": "Eritrea", "code": "ER" },
        { "name": "Estonia", "code": "EE" },
        { "name": "Ethiopia", "code": "ET" },
        { "name": "Falkland Islands (Malvinas)", "code": "FK" },
        { "name": "Faroe Islands", "code": "FO" },
        { "name": "Fiji", "code": "FJ" },
        { "name": "Finland", "code": "FI" },
        { "name": "France", "code": "FR" },
        { "name": "French Guiana", "code": "GF" },
        { "name": "French Polynesia", "code": "PF" },
        { "name": "French Southern Territories", "code": "TF" },
        { "name": "Gabon", "code": "GA" },
        { "name": "Gambia", "code": "GM" },
        { "name": "Georgia", "code": "GE" },
        { "name": "Germany", "code": "DE" },
        { "name": "Ghana", "code": "GH" },
        { "name": "Gibraltar", "code": "GI" },
        { "name": "Greece", "code": "GR" },
        { "name": "Greenland", "code": "GL" },
        { "name": "Grenada", "code": "GD" },
        { "name": "Guadeloupe", "code": "GP" },
        { "name": "Guam", "code": "GU" },
        { "name": "Guatemala", "code": "GT" },
        { "name": "Guernsey", "code": "GG" },
        { "name": "Guinea", "code": "GN" },
        { "name": "Guinea-Bissau", "code": "GW" },
        { "name": "Guyana", "code": "GY" },
        { "name": "Haiti", "code": "HT" },
        { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
        { "name": "Holy See (Vatican City State)", "code": "VA" },
        { "name": "Honduras", "code": "HN" },
        { "name": "Hong Kong", "code": "HK" },
        { "name": "Hungary", "code": "HU" },
        { "name": "Iceland", "code": "IS" },
        { "name": "India", "code": "IN" },
        { "name": "Indonesia", "code": "ID" },
        { "name": "Iran, Islamic Republic Of", "code": "IR" },
        { "name": "Iraq", "code": "IQ" },
        { "name": "Ireland", "code": "IE" },
        { "name": "Isle of Man", "code": "IM" },
        { "name": "Israel", "code": "IL" },
        { "name": "Italy", "code": "IT" },
        { "name": "Jamaica", "code": "JM" },
        { "name": "Japan", "code": "JP" },
        { "name": "Jersey", "code": "JE" },
        { "name": "Jordan", "code": "JO" },
        { "name": "Kazakhstan", "code": "KZ" },
        { "name": "Kenya", "code": "KE" },
        { "name": "Kiribati", "code": "KI" },
        { "name": "Democratic People's Republic of Korea", "code": "KP" },
        { "name": "Korea, Republic of", "code": "KR" },
        { "name": "Kosovo", "code": "XK" },
        { "name": "Kuwait", "code": "KW" },
        { "name": "Kyrgyzstan", "code": "KG" },
        { "name": "Lao People's Democratic Republic", "code": "LA" },
        { "name": "Latvia", "code": "LV" },
        { "name": "Lebanon", "code": "LB" },
        { "name": "Lesotho", "code": "LS" },
        { "name": "Liberia", "code": "LR" },
        { "name": "Libyan Arab Jamahiriya", "code": "LY" },
        { "name": "Liechtenstein", "code": "LI" },
        { "name": "Lithuania", "code": "LT" },
        { "name": "Luxembourg", "code": "LU" },
        { "name": "Macao", "code": "MO" },
        { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
        { "name": "Madagascar", "code": "MG" },
        { "name": "Malawi", "code": "MW" },
        { "name": "Malaysia", "code": "MY" },
        { "name": "Maldives", "code": "MV" },
        { "name": "Mali", "code": "ML" },
        { "name": "Malta", "code": "MT" },
        { "name": "Marshall Islands", "code": "MH" },
        { "name": "Martinique", "code": "MQ" },
        { "name": "Mauritania", "code": "MR" },
        { "name": "Mauritius", "code": "MU" },
        { "name": "Mayotte", "code": "YT" },
        { "name": "Mexico", "code": "MX" },
        { "name": "Micronesia, Federated States of", "code": "FM" },
        { "name": "Moldova, Republic of", "code": "MD" },
        { "name": "Monaco", "code": "MC" },
        { "name": "Mongolia", "code": "MN" },
        { "name": "Montenegro", "code": "ME" },
        { "name": "Montserrat", "code": "MS" },
        { "name": "Morocco", "code": "MA" },
        { "name": "Mozambique", "code": "MZ" },
        { "name": "Myanmar", "code": "MM" },
        { "name": "Namibia", "code": "NA" },
        { "name": "Nauru", "code": "NR" },
        { "name": "Nepal", "code": "NP" },
        { "name": "Netherlands", "code": "NL" },
        { "name": "Netherlands Antilles", "code": "AN" },
        { "name": "New Caledonia", "code": "NC" },
        { "name": "New Zealand", "code": "NZ" },
        { "name": "Nicaragua", "code": "NI" },
        { "name": "Niger", "code": "NE" },
        { "name": "Nigeria", "code": "NG" },
        { "name": "Niue", "code": "NU" },
        { "name": "Norfolk Island", "code": "NF" },
        { "name": "Northern Mariana Islands", "code": "MP" },
        { "name": "Norway", "code": "NO" },
        { "name": "Oman", "code": "OM" },
        { "name": "Pakistan", "code": "PK" },
        { "name": "Palau", "code": "PW" },
        { "name": "Palestinian Territory, Occupied", "code": "PS" },
        { "name": "Panama", "code": "PA" },
        { "name": "Papua New Guinea", "code": "PG" },
        { "name": "Paraguay", "code": "PY" },
        { "name": "Peru", "code": "PE" },
        { "name": "Philippines", "code": "PH" },
        { "name": "Pitcairn", "code": "PN" },
        { "name": "Poland", "code": "PL" },
        { "name": "Portugal", "code": "PT" },
        { "name": "Puerto Rico", "code": "PR" },
        { "name": "Qatar", "code": "QA" },
        { "name": "Reunion", "code": "RE" },
        { "name": "Romania", "code": "RO" },
        { "name": "Russian Federation", "code": "RU" },
        { "name": "Rwanda", "code": "RW" },
        { "name": "Saint Helena", "code": "SH" },
        { "name": "Saint Kitts and Nevis", "code": "KN" },
        { "name": "Saint Lucia", "code": "LC" },
        { "name": "Saint Pierre and Miquelon", "code": "PM" },
        { "name": "Saint Vincent and the Grenadines", "code": "VC" },
        { "name": "Samoa", "code": "WS" },
        { "name": "San Marino", "code": "SM" },
        { "name": "Sao Tome and Principe", "code": "ST" },
        { "name": "Saudi Arabia", "code": "SA" },
        { "name": "Senegal", "code": "SN" },
        { "name": "Serbia", "code": "RS" },
        { "name": "Seychelles", "code": "SC" },
        { "name": "Sierra Leone", "code": "SL" },
        { "name": "Singapore", "code": "SG" },
        { "name": "Slovakia", "code": "SK" },
        { "name": "Slovenia", "code": "SI" },
        { "name": "Solomon Islands", "code": "SB" },
        { "name": "Somalia", "code": "SO" },
        { "name": "South Africa", "code": "ZA" },
        { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
        { "name": "Spain", "code": "ES" },
        { "name": "Sri Lanka", "code": "LK" },
        { "name": "Sudan", "code": "SD" },
        { "name": "Suriname", "code": "SR" },
        { "name": "Svalbard and Jan Mayen", "code": "SJ" },
        { "name": "Swaziland", "code": "SZ" },
        { "name": "Sweden", "code": "SE" },
        { "name": "Switzerland", "code": "CH" },
        { "name": "Syrian Arab Republic", "code": "SY" },
        { "name": "Taiwan", "code": "TW" },
        { "name": "Tajikistan", "code": "TJ" },
        { "name": "Tanzania, United Republic of", "code": "TZ" },
        { "name": "Thailand", "code": "TH" },
        { "name": "Timor-Leste", "code": "TL" },
        { "name": "Togo", "code": "TG" },
        { "name": "Tokelau", "code": "TK" },
        { "name": "Tonga", "code": "TO" },
        { "name": "Tunisia", "code": "TN" },
        { "name": "Turkey", "code": "TR" },
        { "name": "Turkmenistan", "code": "TM" },
        { "name": "Turks and Caicos Islands", "code": "TC" },
        { "name": "Tuvalu", "code": "TV" },
        { "name": "Uganda", "code": "UG" },
        { "name": "Ukraine", "code": "UA" },
        { "name": "United Arab Emirates", "code": "AE" },
        { "name": "United States Minor Outlying Islands", "code": "UM" },
        { "name": "Uruguay", "code": "UY" },
        { "name": "Uzbekistan", "code": "UZ" },
        { "name": "Vanuatu", "code": "VU" },
        { "name": "Venezuela", "code": "VE" },
        { "name": "Viet Nam", "code": "VN" },
        { "name": "Virgin Islands, British", "code": "VG" },
        { "name": "Virgin Islands, U.S.", "code": "VI" },
        { "name": "Wallis and Futuna", "code": "WF" },
        { "name": "Western Sahara", "code": "EH" },
        { "name": "Yemen", "code": "YE" },
        { "name": "Zambia", "code": "ZM" },
        { "name": "Zimbabwe", "code": "ZW" }
    ],
    isCommittee: function (user) {
        const rolesArray = user.get("roles") || [];
        const booleanArray = rolesArray.map(function (role) { return role.toLowerCase().startsWith("committee"); });
        return _.some(booleanArray);
    },
    hasVerifiedEmail: function () {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let user = Parse.User.current();
            if (user) {
                // Update user if possibly out of date
                if (!user.get("emailVerified")) {
                    user = yield ((_a = Parse.User.current()) === null || _a === void 0 ? void 0 : _a.fetch());
                }
                return user === null || user === void 0 ? void 0 : user.get("emailVerified");
            }
            return true; // returns true for non-loggedin user
        });
    },
    parseQuerystring() {
        const parsedQueryObj = queryString.parse(location.search);
        return parsedQueryObj;
    }
}; // return globalThis object
const paidutilsLocalPlugin = {
    install: function (app) {
        app.config.globalProperties.$paidutils = paidutilsObject;
        return paidutilsObject;
    }
};
globalRef.paidutils = paidutilsObject;
export default paidutilsLocalPlugin;
