<template>
  <div v-if="cartExpiryEnabled" :class="{ 'cart-expiry-message-shown': isShown }" class="cart-expiry-message">
    <a href="#" style="color: #fff;" @click.prevent="clickMessage" class="d-flex align-items-center">
      <ion-icon name="cart-outline" style="font-size:1.5rem;"></ion-icon>
      <div>
        <p v-if="countdown > 0" class="mb-0 ml-2">Your cart expires in <b>{{ formattedCountdown }}</b></p>
        <p v-else class="mb-0 ml-2">Your cart has expired</p>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CartExpirationTimer from '../../helpers/cartTimer';
const EXPIRATION_TIME_LOW_MS = 4 * 60 * 1000;
const EXPIRE_MESSAGE_DISPLAY_TIME_MS = 8 * 1000;

export default {
  name: 'CartExpiryStoreMessage',
  watch: {
    cartId: {
      immediate: true,
      handler (cartId) {
        if (!cartId) {
          this.resetComponent();
        } else {
          this.startTimer();
        }
      },
    },
    cartItems: {
      handler (cartItems) {
        if (cartItems.length === 0) {
          this.resetComponent();
        }
      },
    },
  },
  mounted () {
    this.$root.$on('bv::collapse::state', (id, state) => {
      if (id === 'sidebar-no-header') {
        this.cartOpened = state;
      }
    });
  },
  data () {
    return {
      isTimeLow: false,
      formattedCountdown: '',
      countdown: 0,
      isVisible: false,
      expiredMessageShown: false,
      cartOpened: false,
    };
  },
  async created () {
    await this.$store.dispatch('cart/getCart');
  },
  computed: {
    ...mapGetters('cart', ['cartId', 'cartItems', 'expireTime']),
    ...mapGetters('settings', ['cartExpiryTime', 'isCartExpiryTimeEnabled']),
    cartExpiryEnabled () {
      return this.isCartExpiryTimeEnabled;
    },
    isCheckoutRoute () {
      return this.$route.path === '/checkout';
    },
    isShown () {
      const routeCheck = !this.isCheckoutRoute || this.expiredMessageShown;
      return routeCheck && !this.cartOpened && this.isTimeLow && (this.countdown > 0 || this.expiredMessageShown);
    },
  },
  methods: {
    resetComponent () {
      CartExpirationTimer.getInstance().reset(this.cartId);
      this.countdown = 0;
    },
    timerTick (countdown, formatted) {
      this.formattedCountdown = formatted;
      this.countdown = countdown;
      this.isTimeLow = countdown <= EXPIRATION_TIME_LOW_MS;

      if (countdown <= 0) {
        this.expiredMessageShown = true;
        if (!this.isCheckoutRoute && this.cartOpened) {
          this.$root.$emit('bv::toggle::collapse', 'sidebar-no-header')
        }
        setTimeout(() => {
          this.expiredMessageShown = false;
        }, EXPIRE_MESSAGE_DISPLAY_TIME_MS);
      }
    },
    startTimer () {
      CartExpirationTimer.getInstance().startCountdown(this.cartId, this.expireTime);
      CartExpirationTimer.getInstance().subscribe(this.$options.name, ({ countdown, formatted }) => this.timerTick(countdown, formatted));
    },
    clickMessage () {
      if (this.countdown > 0) {
        this.$router.push('/checkout');
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped>
.cart-expiry-message {
  overflow: hidden;
  position: fixed;
  z-index: 100;
  color: white !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  /* Start hidden below view */
  transition: transform 0.12s ease-in-out;
  background-color: #dc2626;
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  width: max-content;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.cart-expiry-message-shown {
  transform: translateX(-50%) translateY(-30px);
  /* Slide into view */
}

@media (max-width: 767px) {
  .cart-expiry-message {
    font-size: 0.8rem !important;
  }
}


.cart-expiry-message a {
  text-decoration: none;
}

.cart-expiry-message a:hover {
  text-decoration: none;
}
</style>