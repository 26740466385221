import { getNoImageURL } from '../helpers/utils';
const Parse = globalThis.Parse;
export const Presentation = Parse.Object.extend("Presentation", {
    initialize: function () {
    },
    name: function () { return this.get("name"); },
    desc: function () { return this.get("desc"); },
    availableOnline: function () { return this.get("online"); },
    logo: function () {
        if (this.get("logo")) {
            return this.get("logo").url();
        }
        return getNoImageURL();
    },
});
