import Vuex from 'vuex';
import user from './modules/user';
import settings from './modules/settings';
import orderPayment from './modules/orderPayment';
import order from './modules/order';
import cart from './modules/cart';
import entitlements from './modules/entitlements';
const store = new Vuex.Store({
    modules: {
        user,
        settings,
        orderPayment,
        order,
        cart,
        entitlements
    }
});
export default store;
