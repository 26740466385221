<template>
  <b-modal :id="modalId" ref="modal" :centered="true" :title="modalTitle">
    <form name="contact-band-form" id="contact-band-form" class="form-horizontal contact-band-form" novalidate
      @submit.prevent="submitForm">
      <div class="message-details small">
        <div class="form-group row">
          <label for="staticFrom" class="col-2 col-form-label text-muted">From:</label>
          <div class="col-10">
            <input type="text" readonly class="form-control-plaintext" :value="userName" id="staticFrom" />
          </div>
        </div>

        <div class="form-group row">
          <label for="staticTo" class="col-2 col-form-label text-muted">To:</label>
          <div class="col-10">
            <input type="text" readonly class="form-control-plaintext" :value="bandName" id="staticTo" />
          </div>
        </div>

        <div class="form-group row">
          <label for="staticSubject" class="col-2 col-form-label text-muted">Subject:</label>
          <div class="col-10">
            <input type="text" readonly class="form-control-plaintext" :value="subject" id="staticSubject" />
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12">
          <textarea v-model="message" autofocus 
            placeholder="Please provide as much detail as possible" id="contact-message" class="form-control" rows="8"
            required></textarea>
        </div>
      </div>
    </form>
    <template #modal-footer>
      <b-button :disabled="loading" class="ml-auto mr-2" @click="closeModal">Cancel</b-button>
      <b-button :disabled="loading" variant="primary" @click="submitForm">
        <span v-if="loading"><i class='fa fa-cog fa-spin fa-fw'></i> Sending ...</span>
        <span v-else>Send</span>
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import DataService from '../../services/DataService';
import platform from 'platform';
const ds = DataService.getInstance();
export default {
  data () {
    return {
      userName: '',
      subject: '',
      message: '',
      extraInfo: {},
      loading: false,
    };
  },
  props: {
    modalId: String,
    dataSubject: String,
  },
  computed: {
    bandName(){
      return this.$paidutils.getBandName();
    },
    modalTitle () {
      return 'Contact ' + this.bandName;
    }
  },
  mounted () {
    const user = Parse.User.current();
    this.userName = user.get("firstName") + " " + user.get("lastName");
    this.subject = this.dataSubject ||  ("Question from " + this.userName);
    this.extraInfo = {
      platform: platform.toString(),
      page: window.location.href
    };
    if(this.dataSubject){
      this.extraInfo.widget= 'order-link';
    }
  },
  methods: {
    closeModal () {
      this.message = '';
      this.userName = '';
      this.subject = '';
      this.$bvModal.hide(this.modalId)
    },
    async submitForm () {
      this.loading = true;
      try {
        const messageToSend = {
          subject: this.subject,
          body: this.message,
        };

        await ds.sendMessageToBand({ message: messageToSend, extraInfo: this.extraInfo });
        this.$bvToast.toast('Message Sent Successfully', {variant: 'success'});
        this.closeModal();
      } catch (error) {
        this.$bvToast.toast(error.message, {variant: 'danger'});
      }
      this.loading = false;
    },
  },
};
</script>