<template>
  <div>
    <CartSkeleton v-if="isLoading" />
    <div v-else>
      <div v-if="cartItems.length === 0" class="row">
        <div class="col">
          <p class="text-center text-muted pt-4">Cart Empty</p>
        </div>
      </div>
      <ul v-else class="list-unstyled">
        <CartItem ref="cartItems" v-for="(cartItem, index) in cartItems" :key="`${cartItem + index}`" :cartItem="cartItem"
          :readonly="readonly" :hideExtraFormFields="hideExtraFormFields"
          @errorEmmited="verifyAllCartItemsAreErrorFree" />
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from './CartItem.vue'
import CartSkeleton from './CartSkeleton'

export default {
  name: "Cart",
  components: {
    CartItem,
    CartSkeleton
  },
  props: {
    readonly: {
      required: false,
      type: Boolean
    },
    hideExtraFormFields: {
      type: Boolean,
      default: false
    },
    refreshCart: Number,
  },
  data () {
    return {
      isLoading: true
    }
  },
  watch: {
    refreshCart: {
      immediate: true,
      handler () {
        this.initialize()
      },
    }
  },
  computed: {
    ...mapGetters('cart', [
      'cartItems',
      'totalPackageCost',
      'totalAddOnCost',
      'cartTotal'
    ]),
    ...mapGetters('settings', ['enablePackageOwners']),
  },
  methods: {
    touchValidationOfAllCartItems () {
      if (this.cartItems.length && !this.hideExtraFormFields && this.enablePackageOwners) {
        return new Promise((resolve) => {
          const isAnyCartItemNotTouched = this.$refs.cartItems.some(item => item.$refs.packageOwnerForm.itemIsTouched == false)
          if (isAnyCartItemNotTouched) {
            this.$refs.cartItems.forEach(item => {
              if (item.$refs.packageOwnerForm.itemIsTouched == false) {
                item.$refs.packageOwnerForm.v$.cartItemOwner.$touch()
              }
            })
          }
          this.verifyAllCartItemsAreErrorFree()
          resolve(true);
        })
      } else {
        return false;
      }
    },
    verifyAllCartItemsAreErrorFree () {
      this.$nextTick(() => {
        const itemHasErrors = this.$refs.cartItems.some(item => item.$refs.packageOwnerForm.v$.$anyError)
        this.$emit('packageOwnerFormHasErrors', itemHasErrors)
      })
    },
    extractPackageOwnersInfo () {
      let packageOwners = [];
      this.$refs.cartItems.map((item, index) => {
        const payload = {
          index: index,
          self: item.$refs.packageOwnerForm.self
        }
        if (payload.self === false) {
          payload.ownerData = { ...item.$refs.packageOwnerForm.cartItemOwner }
        }
        packageOwners.push(payload)
      })
      return packageOwners;
    },
    async initialize () {
      this.isLoading = true
      try {
        await this.$store.dispatch('cart/getCart')
      } catch (error) {
        this.$bvToast.toast(error.message, { variant: "danger" });
      }
      this.isLoading = false
    },
  },
}
</script>