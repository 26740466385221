var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DataService from "../../services/DataService";
import Cookies from 'js-cookie';
import _ from 'lodash';
import { each, isEmpty } from "lodash";
import { getItemTempatesForPackage, getParsePointer, isSoldOut, captureAffiliate, captureAttribution, calculateDiscounts, getLargeImageURL, getSmallImageURL, getPackageImages } from '../../helpers/packages';
import { isSectionSoldOut } from "../../helpers/utils";
import { getItemSelections, updateOrderItemAddOnSelections, updateOrderItemSelectedValues } from "../../helpers/orderItem";
const ds = DataService.getInstance();
// initial state
const state = {
    affiliate: '',
    attrSource: '',
    currency: '',
    entitlements: [],
    itemAddOns: new Map(),
    itemOptions: {},
    note: '',
    packageId: '',
    package: {},
    presentation: {},
    section: {},
    addOns: {},
    event: {},
    queryString: { "": "undefined" },
    referralCode: '',
    itemTemplates: [],
    itemOptionSelections: {},
    itemOptionsAdditionalPriceTotal: 0,
    addOnSelections: [],
    orderItem: {},
    useMeals: false,
    useApplication: false,
    mealsArray: [],
    meal: '',
    soldOut: false,
    connectedStripeKeyHash: '',
    discountPct: 0,
    discountFixed: 0,
    images: [],
    terms: '',
    purchaseRestrictionSegmentComposition: [],
    maxDiscountCodeAllowed: 0
};
// getters
const getters = {
    currency: (_state) => _state.currency.toLowerCase(),
    itemAddOns: (_state) => [..._state.itemAddOns.values()],
    itemOptions: (_state) => _state.itemOptions,
    packageId: (_state) => _state.packageId,
    addOns: (_state) => _state.addOns,
    mealsArray: (_state) => _state.mealsArray,
    meal: (_state) => _state.meal,
    note: (_state) => _state.note,
    affiliate: (_state) => _state.affiliate,
    attrSource: (_state) => _state.attrSource,
    parseEvent: (_state) => _state.event,
    entitlements: (_state) => _state.entitlements,
    itemTemplates: (_state) => _state.itemTemplates,
    itemOptionSelections: (_state) => _state.itemOptionSelections,
    addOnSelections: (_state) => _state.addOnSelections,
    useMeals: (_state) => _state.useMeals,
    images: (_state) => _state.images,
    useApplication: (_state) => _state.useApplication,
    connectedStripeKeyHash: (_state) => _state.connectedStripeKeyHash,
    packageDesc: (_state, getters) => getters.parsePackage ? getters.parsePackage.get('desc') : '',
    packageName: (_state, getters) => {
        let name = '';
        if (getters.parsePackage) {
            name = getters.parsePackage.get('name');
        }
        return name;
    },
    sectionName: (_state, getters) => {
        let name = '';
        if (getters.parseSection) {
            name = getters.parseSection.get('name');
        }
        return name;
    },
    categories: (_state, getters) => {
        const categories = [];
        if (getters.parseSection && getters.parsePresentation) {
            categories.push(getters.parsePresentation.get('name'));
            categories.push(getters.parseSection.get('name'));
        }
        return categories;
    },
    largeImageUrl: (_state, getters) => {
        return getLargeImageURL(getters.parsePackage);
    },
    smallImageUrl: (_state, getters) => {
        return getSmallImageURL(getters.parsePackage);
    },
    parsePackage: (_state) => {
        return isEmpty(_state.package) ? null : _state.package;
    },
    parseSection: (_state) => {
        return isEmpty(_state.section) ? null : _state.section;
    },
    parsePresentation: (_state) => {
        return isEmpty(_state.presentation) ? null : _state.presentation;
    },
    soldOut: (_state, getters) => {
        return isSoldOut(getters.parsePackage) || isSectionSoldOut(getters.parseSection);
    },
    packageCost: (_state, getters) => {
        let cost = 0;
        if (getters.parsePackage) {
            cost = getters.parsePackage.get('cost');
        }
        return cost;
    },
    addOnsCost: (_state, getters) => {
        let addOnCost = 0;
        const addOns = getters.itemAddOns;
        addOns.forEach((addOn) => addOnCost += addOn.cost);
        return addOnCost;
    },
    orderTotal: (_state, getters) => {
        return getters.packageCost + getters.addOnsCost + (getters.itemOptionsAdditionalPriceTotal || 0);
    },
    itemOptionsAdditionalPriceTotal: (_state, getters) => {
        let totalAdditionalPrice = 0;
        each(getters.itemOptions, (option) => {
            totalAdditionalPrice += option.deltaPrice ? option.deltaPrice : 0;
        });
        each(getters.itemAddOns, (option) => {
            totalAdditionalPrice += option.deltaPrice ? option.deltaPrice : 0;
        });
        return totalAdditionalPrice;
    },
    discountPct: (_state) => _state.discountPct,
    discountFixed: (_state) => _state.discountFixed,
    discount: (_state, getters) => {
        const total = getters.orderTotal;
        const pct = Math.min(1, Number(getters.discountPct) / 100);
        const fixed = Math.min(getters.orderTotal, getters.discountFixed);
        return total * pct + fixed;
    },
    cartItem: (_state, getters) => {
        const itemOptions = {};
        each(getters.itemOptions, (option, key) => {
            itemOptions[key] = option.selected;
        });
        const addToCartItem = {
            affiliate: getters.affiliate,
            attrSource: getters.attrSource,
            currency: _state.currency,
            entitlements: getters.entitlements,
            itemAddOns: getters.itemAddOns,
            itemOptions,
            note: getters.note,
            meal: _state.meal,
            queryString: { "": "undefined" },
            referralCode: undefined,
            package_id: getters.parsePackage.id,
            section_id: getters.parseSection.id,
            presentation_id: getters.parsePresentation.id,
            thumbUrl: getters.smallImageUrl
        };
        return addToCartItem;
    },
    defaultCommitteeMemberId: (_state, getters) => {
        const member = getters.parseEvent.get('defaultCommitteeMember');
        return member ? member.id : null;
    },
    terms: (_state) => {
        return _state.terms;
    },
    purchaseRestrictionSegmentComposition: (_state) => {
        return _state.purchaseRestrictionSegmentComposition;
    },
    maxDiscountCodeAllowed: (_state) => {
        return _state.maxDiscountCodeAllowed;
    }
};
// actions
const actions = {
    getPackageFromOrderItem: ({ commit, getters }, orderItem) => __awaiter(void 0, void 0, void 0, function* () {
        commit('resetOrder');
        const packageId = orderItem.package().id;
        commit('setPackageId', packageId);
        const PackageMembership = Parse.Object.extend("PackageMembership");
        const [eventPackage, itemTemplates, packageAddons, globalAddons, packageImages] = yield Promise.all([
            ds.getPackage(packageId),
            getItemTempatesForPackage(packageId),
            ds.getPackageAddOns(getParsePointer('Package', packageId), PackageMembership),
            ds.getGlobalAddOns(PackageMembership),
            getPackageImages(packageId)
        ]);
        const selections = getItemSelections(orderItem);
        commit('itemSelections', selections.items);
        commit('addOnSelections', selections.addOns);
        commit('setMeal', selections.meal);
        commit('note', selections.note);
        commit('setPackage', eventPackage);
        commit('setImages', packageImages);
        commit('setSection', eventPackage.get('section'));
        commit('setPresentation', getters.parseSection.get('presentation'));
        commit('setEvent', getters.parsePresentation.get('season'));
        commit('setCurrency', eventPackage.get('currency'));
        commit('itemTemplates', itemTemplates);
        commit('setMealsArray', getters.parseEvent.get('mealsArray'));
        commit('useMeals', getters.parseEvent.get('useMeals') && !eventPackage.get('section').get('mealsDisabled'));
        commit('useApplication', eventPackage.get('useApplication'));
        commit('addOns', packageAddons.concat(globalAddons));
        commit('connectedStripeKeyHash', getters.parseSection.get('connectedStripeKeyHash'));
        captureAttribution();
        captureAffiliate({ packageId });
        commit('attrSource', Cookies.get('source'));
        commit('affiliate', Cookies.get('affiliate'));
    }),
    getPackage: ({ commit, getters }, packageId) => __awaiter(void 0, void 0, void 0, function* () {
        commit('resetOrder');
        commit('setPackageId', packageId);
        const PackageMembership = Parse.Object.extend("PackageMembership");
        const [eventPackage, itemTemplates, packageAddons, globalAddons, packageImages] = yield Promise.all([
            ds.getPackage(packageId),
            getItemTempatesForPackage(packageId),
            ds.getPackageAddOns(getParsePointer('Package', packageId), PackageMembership),
            ds.getGlobalAddOns(PackageMembership),
            getPackageImages(packageId)
        ]);
        commit('setPackage', eventPackage);
        commit('setImages', packageImages);
        commit('setSection', eventPackage.get('section'));
        commit('setPresentation', getters.parseSection.get('presentation'));
        commit('setEvent', getters.parsePresentation.get('season'));
        commit('setCurrency', eventPackage.get('currency'));
        commit('itemTemplates', itemTemplates);
        commit('setMealsArray', getters.parseEvent.get('mealsArray'));
        commit('useMeals', getters.parseEvent.get('useMeals') && !eventPackage.get('section').get('mealsDisabled'));
        commit('useApplication', eventPackage.get('useApplication'));
        commit('addOns', packageAddons.concat(globalAddons));
        commit('connectedStripeKeyHash', getters.parseSection.get('connectedStripeKeyHash'));
        commit('terms', getters.parseSection.get('presentation').get('season').get('terms'));
        commit('purchaseRestrictionSegmentComposition', eventPackage.get('purchaseRestrictionSegmentComposition'));
        commit('maxDiscountCodeAllowed', eventPackage.get('maxDiscountCodeAllowed'));
        captureAttribution();
        captureAffiliate({ packageId });
        commit('attrSource', Cookies.get('source'));
        commit('affiliate', Cookies.get('affiliate'));
    }),
    updateEntitlements: ({ commit, getters, rootGetters }, { selected, entitlementId }) => {
        selected ? commit('addEntitlement', entitlementId) : commit('removeEntitlement', entitlementId);
        const selectedEntitlements = getters.entitlements;
        const userEntitlements = rootGetters['entitlements/paymentEntitlements'];
        const entitlements = userEntitlements.filter(entitlement => selectedEntitlements.includes(entitlement.id));
        const [discountPct, discountFixed] = calculateDiscounts(entitlements);
        commit('discountPct', discountPct);
        commit('discountFixed', discountFixed);
    },
    applyOrderChanges: ({ commit, getters }, orderItemId) => __awaiter(void 0, void 0, void 0, function* () {
        const itemOptionSelections = getters.itemOptionSelections;
        const changes = [];
        for (const optionId in itemOptionSelections) {
            const selection = itemOptionSelections[optionId];
            if (selection.newValue !== selection.origValue) {
                changes.push({
                    itemInstance: selection.instanceId,
                    option: optionId,
                    newVal: selection.newValue
                });
            }
        }
        const addOnSelections = getters.addOnSelections;
        const itemAddOns = getters.itemAddOns;
        // check for addOns being added to order
        const addOnIdSelections = addOnSelections.map(addOnSelection => addOnSelection.membershipId);
        const newAddOns = itemAddOns.filter((itemAddOn) => !addOnIdSelections.includes(itemAddOn.id));
        const orderItemUpdates = newAddOns.map((itemAddOn) => {
            return {
                id: itemAddOn.id,
                parent: orderItemId,
                options: itemAddOn.options
            };
        });
        // check for addOns being removed from order
        const itemAddOnsTemplateIds = itemAddOns.map((itemAddOn) => itemAddOn.id);
        const removedAddOns = addOnIdSelections.filter((addOnId) => !itemAddOnsTemplateIds.includes(addOnId));
        const addOnsToRemove = addOnSelections.filter(addOn => removedAddOns.includes(addOn.membershipId));
        yield Parse.Cloud.run("editOrderItemFromEndUser", { orderItem: orderItemId, changes, orderItemUpdates, meal: getters.meal, note: getters.note });
        for (const addOn of addOnsToRemove) {
            yield Parse.Cloud.run("removeAddOnFromOrderItemByEndUser", { orderItemId, addOnId: addOn.instanceId });
        }
    })
};
// mutations
const mutations = {
    addOns(state, addOns) {
        state.addOns = _.orderBy(addOns, [(addon) => {
                return _.isNumber(addon.get("sortOrder")) ? addon.get("sortOrder") : addon.createdAt;
            }
        ], [((addon) => { return _.isNumber(addon.get("sortOrder")) ? "asc" : "desc"; })]);
    },
    setPackageId(state, packageId) {
        state.packageId = packageId;
    },
    setImages(state, images) {
        state.images = [];
        for (const image of images) {
            state.images.push({
                originalUrl: image.get('image') ? image.get('image').url() : null,
                largeUrl: image.get('thumbnail_large') ? image.get('thumbnail_large').url() : null
            });
        }
    },
    setPackage(state, eventPackage) {
        state.package = eventPackage;
    },
    setSection(state, section) {
        state.section = section;
    },
    setPresentation(state, presentation) {
        state.presentation = presentation;
    },
    setEvent(state, event) {
        state.event = event;
    },
    setCurrency(state, currency) {
        const defaultCurrency = Parse.Config.current().get('stripeCurrency');
        state.currency = currency || defaultCurrency;
    },
    setMealsArray(state, mealsArray) {
        state.mealsArray = mealsArray || [];
    },
    setMeal(state, meal) {
        state.meal = meal;
    },
    useMeals(state, useMeals) {
        state.useMeals = useMeals;
    },
    useApplication(state, useApplication) {
        state.useApplication = useApplication;
    },
    itemTemplates(state, itemTemplates) {
        state.itemTemplates = _.orderBy(itemTemplates, [(itemTemplate) => {
                return _.isNumber(itemTemplate.sortOrder) ? itemTemplate.sortOrder : itemTemplate.createdAt;
            }
        ], [((itemTemplate) => { return _.isNumber(itemTemplate.sortOrder) ? "asc" : "desc"; })]);
    },
    addItemOption(state, itemOption) {
        state.itemOptions = Object.assign({}, state.itemOptions, itemOption);
        // This handles updating order selection values
        updateOrderItemSelectedValues(itemOption, state.itemOptionSelections);
    },
    itemOptions(state, itemOptions) {
        state.itemOptions = itemOptions;
    },
    updateItemAddOn(state, itemAddOn) {
        state.itemAddOns = new Map(state.itemAddOns.set(itemAddOn.id, itemAddOn));
        // This handles updating order selection values
        updateOrderItemAddOnSelections(itemAddOn, state.itemOptionSelections);
    },
    removeItemAddOn(state, membershipId) {
        state.itemAddOns.delete(membershipId);
        state.itemAddOns = new Map(state.itemAddOns);
    },
    note(state, note) {
        state.note = note;
    },
    affiliate(state, affiliate) {
        state.affiliate = affiliate;
    },
    attrSource(state, attrSource) {
        state.attrSource = attrSource;
    },
    connectedStripeKeyHash(state, connectedStripeKeyHash) {
        state.connectedStripeKeyHash = connectedStripeKeyHash;
    },
    addEntitlement(state, entitlementId) {
        if (!state.entitlements.includes(entitlementId)) {
            state.entitlements = state.entitlements.concat(entitlementId);
        }
    },
    removeEntitlement(state, entitlementId) {
        state.entitlements = state.entitlements.filter(entitlement => entitlement !== entitlementId);
    },
    discountPct(state, discountPct) {
        state.discountPct = discountPct;
    },
    discountFixed(state, discountFixed) {
        state.discountFixed = discountFixed;
    },
    itemSelections(state, itemOptionSelections) {
        state.itemOptionSelections = itemOptionSelections;
    },
    addOnSelections(state, addOnSelections) {
        state.addOnSelections = addOnSelections;
    },
    setOrderItem(state, orderItem) {
        state.orderItem = orderItem;
    },
    terms(state, terms) {
        state.terms = terms;
    },
    purchaseRestrictionSegmentComposition(state, purchaseRestrictionSegmentComposition) {
        state.purchaseRestrictionSegmentComposition = purchaseRestrictionSegmentComposition;
    },
    maxDiscountCodeAllowed(state, maxDiscountCodeAllowed) {
        state.maxDiscountCodeAllowed = maxDiscountCodeAllowed;
    },
    resetOrder(state) {
        state.affiliate = '';
        state.attrSource = '';
        state.currency = '';
        state.entitlements = [];
        state.itemAddOns = new Map();
        state.itemOptions = {};
        state.note = '';
        state.packageId = '';
        state.package = {};
        state.presentation = {};
        state.section = {};
        state.addOns = {};
        state.event = {};
        state.queryString = { "": "undefined" };
        state.referralCode = '';
        state.itemTemplates = [];
        state.useMeals = false;
        state.mealsArray = [];
        state.meal = '';
        state.soldOut = false;
        state.discountPct = 0;
        state.discountFixed = 0;
        state.itemOptionSelections = {};
        state.itemOptionsAdditionalPriceTotal = 0;
        state.addOnSelections = [];
        state.terms = '';
        state.purchaseRestrictionSegmentComposition = [];
        state.maxDiscountCodeAllowed = 0;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
