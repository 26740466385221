var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DataService from "../../services/DataService";
import { each } from 'lodash';
const ds = DataService.getInstance();
// initial state
const state = {
    selectedCost: 0,
    addOnsToBuy: [],
    additionalPaymentOrderId: '',
    addonsAdditionalPriceTotal: 0,
    currency: '',
    cartFees: []
};
// getters
const getters = {
    addOnsToBuy: (_state) => _state.addOnsToBuy,
    selectedCost: (_state) => _state.selectedCost,
    currency: (_state) => _state.currency,
    additionalPaymentOrderId: (_state) => _state.additionalPaymentOrderId,
    cartFees: (_state) => _state.cartFees,
    cartFeesTotal: (_state) => _state.cartFees.reduce((memo, fee) => memo + fee.amount, 0),
    subTotal: (_state, getters) => {
        return getters.selectedCost + getters.addOnCost + getters.addonsAdditionalPriceTotal;
    },
    totalCost: (_state, getters) => {
        return getters.subTotal + getters.cartFeesTotal;
    },
    addOnCost: (_state) => {
        return _state.addOnsToBuy.reduce((total, addOn) => {
            if (addOn && addOn.membershipAddon && addOn.membershipAddon.get('itemTemplate').get('cost')) {
                return total + addOn.membershipAddon.get('itemTemplate').get('cost');
            }
            return total;
        }, 0);
    },
    addonsAdditionalPriceTotal: (_state) => {
        return _state.addonsAdditionalPriceTotal;
    },
};
// actons
const actions = {
    getConvenienceFeesForCart: ({ commit, getters, rootGetters }) => __awaiter(void 0, void 0, void 0, function* () {
        const shippingAddress = rootGetters['cart/shippingAddress'];
        const subTotal = getters.selectedCost + getters.addOnCost;
        const cartFees = yield ds.getCartFees({ subTotal, shippingAddress });
        commit('cartFees', cartFees);
    }),
    getConvenienceFeesForOrder: ({ commit, getters }) => __awaiter(void 0, void 0, void 0, function* () {
        const subTotal = getters.selectedCost + getters.addOnCost;
        const orderId = getters.additionalPaymentOrderId;
        const cartFees = yield ds.getOrderFees(subTotal, orderId);
        commit('cartFees', cartFees);
    })
};
// mutations
const mutations = {
    clearAll(state) {
        state.selectedCost = 0;
        state.addOnsToBuy = [];
        state.additionalPaymentOrderId = '';
        state.addonsAdditionalPriceTotal = 0;
        state.currency = '';
        state.cartFees = [];
    },
    selectedCost(state, selectedCost) {
        state.selectedCost = selectedCost;
    },
    newAddOnToBuyList(state, purchaseAddon) {
        state.addOnsToBuy.push(purchaseAddon);
    },
    removeAddOnFromBuyList(state, removeAddOnId) {
        state.addOnsToBuy = state.addOnsToBuy.filter((addOn) => {
            return addOn.id != removeAddOnId;
        });
    },
    updateAddOnToBuy(state, purchaseAddon) {
        function calculateAdditionalPrice() {
            let totalAdditionalPrice = 0;
            each(state.addOnsToBuy, (option) => {
                totalAdditionalPrice += option.deltaPrice ? option.deltaPrice : 0;
            });
            state.addonsAdditionalPriceTotal = totalAdditionalPrice;
        }
        for (let index = 0; index < state.addOnsToBuy.length; index++) {
            const addon = state.addOnsToBuy[index];
            if (addon.id === purchaseAddon.id) {
                state.addOnsToBuy[index] = purchaseAddon;
                calculateAdditionalPrice();
                return;
            }
        }
        // addon not found in list
        state.addOnsToBuy.push(purchaseAddon);
        calculateAdditionalPrice();
    },
    additionalPaymentOrderId(state, additionalPaymentOrderId) {
        state.additionalPaymentOrderId = additionalPaymentOrderId;
    },
    cartFees(state, cartFees) {
        state.cartFees = cartFees;
    },
    currency(state, currency) {
        state.currency = currency;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
