// initial state
const state = {};
// getters
const getters = {
    user: () => Parse.User.current()
};
// actons
const actions = {};
// mutations
const mutations = {};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
