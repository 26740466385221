import _ from 'lodash';
import moment from 'moment';
import { getNoImageURL } from '../helpers/utils';
const Parse = globalThis.Parse;
export const User = Parse.Object.extend("User", {
    initialize: function () {
    },
    defaults: {
        username: "",
        email: "",
        phone: "",
        address: "",
    },
    getOrderCount: function () { return this.get("orderCount"); },
    firstName: function () { return this.get("firstName"); },
    lastName: function () { return this.get("lastName"); },
    email: function () { return this.getEmail(); },
    address: function () { return this.get("address"); },
    phone: function () { return this.get("phone"); },
    gender: function () { return paidutils.getUserGenderName(this.get("gender")); },
    stripeCustomerId: function () { return this.get("stripeCustomerId"); },
    fullName: function () {
        const fullname = [];
        if (this.get("firstName")) {
            fullname.push(this.get("firstName"));
        }
        if (this.get("lastName")) {
            fullname.push(this.get("lastName"));
        }
        if (fullname.length === 0) {
            return "None Given";
        }
        else {
            return fullname.join(' ');
        }
    },
    image: function () {
        if (this.get("profileImageThumbnail")) {
            return this.get("profileImageThumbnail").url();
        }
        else {
            return getNoImageURL();
        }
    },
    country: function () {
        const code = this.get("country");
        return globalThis.getCountryFromCode(code);
    },
    roles: function () {
        const userRoles = this.get("roles");
        if (userRoles && Array.isArray(userRoles) && userRoles.length) {
            return userRoles.join(", ");
        }
        return "";
    },
    rolesArray: function () {
        return this.get("roles");
    },
    shortenedName: function () {
        return this.get("firstName") + " " + this.get("lastName").charAt(0) + ".";
    },
    isCommittee: function () {
        const rolesArray = this.get("roles") || [];
        const booleanArray = rolesArray.map(function (role) { return role.toLowerCase().startsWith("committee"); });
        return _.some(booleanArray);
    },
    isGroup: function () { return _.includes(this.get("roles"), "group"); },
    isAdmin: function () { return _.includes(this.get("roles"), "admin"); },
    createdAtTimestamp: function () { return moment(this.createdAt).format("X"); },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    }
});
