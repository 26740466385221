export function hasPurchaseRight(parsePackage, entitlements) {
    if (parsePackage && parsePackage.get('useApplication')) {
        return entitlements.some(entitlement => {
            return isPurchaseRightEntitlement(parsePackage.id, entitlement);
        });
    }
    return true;
}
export function isPurchaseRightEntitlement(packageId, entitlement) {
    const { right, type } = entitlement.template;
    return type === 'PURCHASE' && (right.packageIds || []).includes(packageId);
}
export function getApplicationForPackage(packageId, entitlements) {
    const applications = entitlements.filter(entitlement => {
        return isPurchaseRightEntitlement(packageId, entitlement);
    });
    // it is technically possible to have more than one application, if that's the case return the first
    const application = applications.length ? applications[0] : null;
    return application;
}
