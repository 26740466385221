import _ from 'lodash';
const Parse = globalThis.Parse;
export const OrderItem = Parse.Object.extend("OrderItem", {
    order: function () { return this.get("order"); },
    package: function () { return this.get("package"); },
    instances: function () { return this.instances; },
    stitch: function (entry) {
        this.instances = entry._instances;
        _.each(this.instances, function (inst) {
            const match = _.find(entry.instances, function (item, key) { return inst.id == key; });
            inst.stitch(match);
        });
    },
    mealPreference: function () {
        //  don't hide "meal_value" from receipt, if any
        return this.get("meal_value");
    },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    },
    isCollected: function () {
        return this.get('collectionStatus') === 'collected' || this.get('collectionStatus') === 'COLLECTED';
    },
    isCarnivalEvent: function () {
        const eventPackage = this.get('package');
        const section = eventPackage ? eventPackage.get('section') : null;
        const presentation = section ? section.get('presentation') : null;
        const season = presentation ? presentation.get('season') : null;
        const eventType = season ? season.get('type') : null;
        return eventType === 'costumed';
    },
    collectionDetails: function () {
        return this.get("collection");
    },
});
