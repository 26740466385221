import _ from 'lodash';
const Parse = globalThis.Parse;
export const ItemInstance = Parse.Object.extend("ItemInstance", {
    // initialize: function(parseItemInstance: any) {
    //   this.template = parseItemInstance.get("template");
    //   this.itemOptions = parseItemInstance.get("itemOptions");
    // },
    initialize: function () {
    },
    defaults: {
        state: "Requested",
        options: [],
        addons: [],
        itemTemplate: null,
        order: null
    },
    itemTemplate: function () {
        const params = this.get("itemTemplate").attributes;
        params.id = this.get("itemTemplate").id;
        return new globalThis.Website.Models.ItemTemplate(params);
    },
    itemOptions: function () { return this.itemOptions; },
    stitch: function (entry) {
        this.itemOptions = entry._itemOptions;
        this.addOns = entry._addons;
        /*
        _.each(this.addOns, function(inst) {
          var match = _.find(entry.addons, function(item, key) { return inst.id == key;});
          inst.stitch(match);
        });
        */
    },
    optionNameList: function () {
        const list = [];
        _.each(this.itemOptions, function (itemOption) {
            list.push(itemOption.get("optionValue"));
        });
        return list.join(", ");
    },
    template: function () {
        return this.get("itemTemplate");
    },
    itemTemplateName: function () {
        return this.itemTemplate().name();
    },
    itemTemplateImage: function () {
        return this.itemTemplate().imageUrl();
    },
    state: function () {
        return this.get("state");
    },
    options: function () {
        const options = [];
        const arr = this.get("options");
        for (const opt in arr) {
            const obj = new globalThis.Website.Models.OptionInstance(arr[opt].attributes);
            options.push(obj);
        }
        return options;
    },
    optionNameWithTypeNameList: function () {
        // console.log("depreciated");
        const list = [];
        _.each(this.itemOptions, function (itemOption) {
            if (!itemOption.get("option")) {
                console.log(itemOption.id + " appears to point at deleted option");
            }
            const optionName = itemOption.get("option").get("name");
            const optionValueName = itemOption.get("optionValue");
            list.push(optionName + "-" + optionValueName);
        });
        return list.join(", ");
    },
    parse: function (response, options) {
        return response;
    }
});
