<template>
  <div class="mt-2">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" :id="checkboxuuid" v-model="self">
      <label class="custom-control-label" style="font-size: 14px; font-weight: bold;" :for="checkboxuuid">This package
        belongs to me</label>
    </div>
    <li v-if="!self">
      <div class="row mb-2">
        <div class="col-sm-12 col-md-9">
          <div class="row">
            <div class="col">
              <b-form-group label="First Name:" label-for="firstName" class="mb-0 small text-muted">
                <b-form-input id="firstName" name="firstName" v-model="v$.cartItemOwner.firstName.$model"
                  :state="validateState('firstName')" autocomplete="off" aria-describedby="firstName-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="firstName-feedback">First name is required</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Last Name:" label-for="lastName" class="mb-0 small text-muted">
                <b-form-input id="lastName" name="lastName" v-model="v$.cartItemOwner.lastName.$model"
                  :state="validateState('lastName')" autocomplete="off" aria-describedby="lastName-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="lastName-feedback">Last name is required</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12 col-md-9">
          <b-form-group label="Email:" label-for="email" class="mb-0 small text-muted">
            <b-form-input id="email" name="email" v-model="v$.cartItemOwner.email.$model" :state="validateState('email')"
              autocomplete="off" aria-describedby="email-feedback"></b-form-input>
            <b-form-invalid-feedback id="email-feedback">A valid email is required</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12 col-md-9">
          <b-form-group label="Gender:" label-for="gender" class="mb-0 small text-muted">
            <b-form-select v-model="v$.cartItemOwner.gender.$model" :options="genderOptions"></b-form-select>
          </b-form-group>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
  name: 'PackageOwnerForm',
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      self: true,
      cartItemOwner: {
        firstName: '',
        lastName: '',
        email: '',
        gender: 0,
      },
      itemIsTouched: false,
    };
  },
  validations: {
    cartItemOwner: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        email,
        required
      },
      gender: {
        required
      }
    }
  },
  computed: {
    checkboxuuid(){
      return 'checkboxid'+ Math.random(1);
    },
    genderOptions () { return this.$paidutils.getUserGenderOptions().map((gender, index) => ({ value: index, text: gender })) },
  },
  watch: {
    self: {
      handler (self) {
        if (self == true) {
          this.resetForm();
        } else {
          this.v$.cartItemOwner.$touch()
          this.itemIsTouched = false;
        }
      }
    },
    'v$.$anyError': {
      handler (value) {
        this.itemIsTouched = true;
        this.$emit('errorEmmited', value)
      }
    }
  },
  methods: {
    resetForm () {
      this.cartItemOwner = {
        firstName: '',
        lastName: '',
        email: '',
        gender: 0,
      };
      this.v$.$reset();
      this.itemIsTouched = true;
    },
    validateState (name) {
      const { $dirty, $error } = this.v$.cartItemOwner[name];
      return $dirty ? !$error : null;
    },
  }
}
</script>