const Parse = globalThis.Parse;
export const ItemInstanceOption = Parse.Object.extend("ItemInstanceOption", {
    url: '',
    initialize: function () {
    },
    name: function () { return this.get("optionName"); },
    value: function () { return this.get("optionValue"); },
    defaults: {},
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    }
});
