import { isEmpty } from "lodash";
export function getItemSelections(orderItem) {
    const addOns = [];
    const items = {};
    const meal = orderItem.get('meal_value');
    const note = orderItem.get('note');
    orderItem.instances.forEach((instance) => {
        var _a;
        if (instance.addOns.length) {
            addOns.push({
                membershipId: instance.addOns[0].id,
                addOnId: (_a = instance.addOns[0].get('itemTemplate')) === null || _a === void 0 ? void 0 : _a.id,
                instanceId: instance.id
            });
        }
        instance.itemOptions.forEach((optionInstance) => {
            const optionId = optionInstance.get('option').id;
            const instanceId = optionInstance.get('itemInstance').id;
            items[optionId] = {
                name: optionInstance.get('optionName'),
                origValue: optionInstance.get('optionValue'),
                newValue: optionInstance.get('optionValue'),
                instanceId
            };
        });
    });
    return { items, addOns, meal, note };
}
export function updateOrderItemSelectedValues(itemOption, itemOptionSelections) {
    if (!isEmpty(itemOptionSelections)) {
        for (const [, entry] of Object.entries(itemOption)) {
            entry.selected.forEach((selection) => {
                const optionId = selection.option_id;
                const value = selection.value;
                const orderSelection = itemOptionSelections[optionId];
                if (orderSelection) {
                    orderSelection.newValue = value;
                }
            });
        }
    }
}
export function updateOrderItemAddOnSelections(itemAddOn, itemOptionSelections) {
    if (!isEmpty(itemOptionSelections)) {
        itemAddOn.options.forEach((option) => {
            const optionId = option.option_id;
            const value = option.value;
            const orderSelection = itemOptionSelections[optionId];
            if (orderSelection) {
                orderSelection.newValue = value;
            }
        });
    }
}
