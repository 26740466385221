import { round } from 'lodash';
import moment from 'moment';
const noImageUrl = '../images/no-image.jpg';
const filterFunctions = {
    formatMoney(value, currency) {
        currency = currency || paidutils.getCurrencyCode(); // defaults to global currency
        const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(value);
        if (!formatted) {
            return "N/A";
        }
        return formatted;
    },
    roundTo2(value) {
        return round(value, 2).toFixed(2);
    },
    prettyDate(dateInp) {
        const date = moment(dateInp);
        return date.format("D MMM YYYY");
    },
    prettyTime(timeInp) {
        return moment(timeInp).format("h:mmA");
    },
    roundTo0(value) {
        return round(value, 2).toFixed(0);
    },
    pluralise(value, count) {
        return count !== 1 ? `${value}s` : `${value}`;
    },
    fullname(user) {
        try {
            const parseUser = user.get('user') || user;
            return `${parseUser.get('firstName')} ${parseUser.get('lastName')}`;
        }
        catch (err) {
            return '-';
        }
    },
    package(orderItem) {
        try {
            return orderItem.get('package');
        }
        catch (err) {
            return null;
        }
    },
    section(orderItem) {
        try {
            return orderItem.get('package').get('section');
        }
        catch (err) {
            return null;
        }
    },
    presentation(orderItem) {
        try {
            return orderItem.get('package').get('section').get('presentation');
        }
        catch (err) {
            return null;
        }
    },
    template(instance) {
        try {
            return instance.get('template');
        }
        catch (err) {
            return null;
        }
    },
    optionNameList(instance) {
        try {
            return instance.get('optionNameList');
        }
        catch (err) {
            return null;
        }
    },
    name(parseObject) {
        try {
            return parseObject.get('name');
        }
        catch (err) {
            return 'Unknown';
        }
    },
    image(parseObject) {
        try {
            return parseObject.get('image')._url;
        }
        catch (err) {
            return noImageUrl;
        }
    },
};
export default filterFunctions;
