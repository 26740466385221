<template>
  <div class="overlay" :hidden="!isLoading">
    <i><Loader :isLoading="isLoading" /></i>
  </div>
</template>


<script>
import Loader from './Loader'

export default {
  name: "FullScreenLoader",
  components: {
    Loader
  },
  props: {
    isLoading: false
  }
}
</script>
