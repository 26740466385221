import _ from 'lodash';
import { useHead } from '@unhead/vue';
export function isMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }
    return false;
}
export function isSectionSoldOut(section) {
    if (!section) {
        return false;
    }
    const soldPackageLimit = section.get('soldPackageLimit');
    const qtySold = section.get('qtySold');
    const soldOutOverride = section.get('soldOutOverride');
    if (!soldPackageLimit || soldPackageLimit === 0) { // unlimited inventory
        return soldOutOverride;
    }
    return (qtySold >= soldPackageLimit) || soldOutOverride;
}
export function isSupportedBrowser() {
    const userAgent = navigator.userAgent;
    const isIE = userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1;
    if (isIE) {
        return false;
    }
    return true;
}
/* eslint-disable @typescript-eslint/no-unused-vars */
let _cachedStripePublicKey;
export const getAppConfig = function () {
    return function () { return Parse.Config.get(); };
}();
export function getQueryStringFromHash() {
    const query_string = {};
    let query = window.location.hash.substring(1);
    query = query.split("?"); //get everything after the first ?
    if (query.length) {
        query = query[1];
    }
    if (!query) {
        return {};
    }
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        }
        else if (typeof query_string[pair[0]] === "string") {
            const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        }
        else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}
export function getQueryString() {
    const query_string = {};
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        }
        else if (typeof query_string[pair[0]] === "string") {
            const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        }
        else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}
export function uploadDataURL(dataURL, fileExtension) {
    const ext = fileExtension || "jpg";
    const base64 = dataURL.split('base64,')[1];
    const name = guid() + "." + ext;
    const parseFile = new Parse.File(name, { base64: base64 }, ext);
    return parseFile.save();
}
function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}
// toFixed() does round numbers like 147.055 to 147.06. Fix for this issue
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round$revision/1383484
export function round(number, precision) {
    const shift = function (number, exponent) {
        const numArray = ("" + number).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
    };
    return shift(Math.round(shift(number, +precision)), -precision);
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const InvalidSessionToken = 209;
const CannotReachServer = 100;
const PaymentError = 900;
export function errorHandler(e) {
    var _a, _b;
    //if we're an array.  call us back with each individual error;
    if (e instanceof Array) {
        e.forEach(function (err) {
            errorHandler(err);
        });
        return;
    }
    const requesterEmail = Parse.User.current() ? (_b = (_a = Parse.User) === null || _a === void 0 ? void 0 : _a.current()) === null || _b === void 0 ? void 0 : _b.get('email') : '';
    const errorMessage = "If the problem persists, please reach out to us at <a href='mailto:support@playmas.app?cc=&amp;bcc=&amp;subject=[Customer%20Error]:&amp;body=#tag%20customer_error%0D%0A#requester%20" + requesterEmail + "%0D%0A----%0D%0A%0D%0A%3CEnter+details+of+error+here%3E'>support@playmas.app</a>.";
    const error = e;
    try {
        const errorObject = JSON.parse(error.message.message);
        const errorPackage = error.message;
        if (errorPackage.code == PaymentError) {
            const errorReason = errorObject.message;
            const message = "We had a problem charging your credit card. No charge was made.<br><br>Reason: <code>" + errorReason + "<code>";
            showModal("No charge made", message);
        }
        else {
            let message = "<br><br>Error details: <code>" + errorObject.message + "</code>";
            message += "<br>Error ID: <code>" + errorObject.code + "</code>.";
            showModal("Opps - We've encountered an Error", message);
        }
    }
    catch (e) {
        // not an error object
        if (error.code == InvalidSessionToken) {
            window.location.href = '/login';
        }
        else if (error.code == CannotReachServer) {
            const message = "Please check your internet connection.  Your browser is having trouble communicating with the server<br><br>Error Code: <code>" + error.code + "<code>";
            showModal("Connection Issue", message);
        }
        else {
            logError(error);
            let message = errorMessage;
            message += "<br><br>Error details: <code>" + capitalizeFirstLetter(JSON.stringify(error)) + "</code>";
            message += "<br>Error ID: <code>" + error.code + "</code>.";
            showModal("Opps - We've encountered an Error", message);
        }
    }
}
export function analyticsTrack(event, props) {
    if (globalThis.analytics) {
        globalThis.analytics.track(event, props);
    }
}
export function setAnalyticsPage(route, item_id) {
    if (globalThis.analytics) {
        globalThis.analytics.page(route, {
            item_id: item_id
        });
    }
}
export function setAnalyticsUser(user) {
    if (globalThis.analytics && user && user.id) {
        globalThis.analytics.identify(user.id, {
            name: user.get('firstName') + ' ' + user.get('lastName'),
            email: user.get('email'),
            env: globalThis.Target.desc,
            server: globalThis.Target.server,
            gender: paidutils.getUserGenderName(user.get('gender')),
            roles: user.get('roles'),
            country: user.get('country')
        });
    }
}
export function setBugsnagUser(user) {
    if (globalThis.bugsnagClient) {
        if (user && user.id) {
            globalThis.bugsnagClient.user = {
                id: user.id,
                name: user.get('firstName') + ' ' + user.get('lastName'),
                email: user.get('email')
            };
        }
        else {
            globalThis.bugsnagClient.user = {};
        }
    }
}
function logError(e) {
    if (globalThis.bugsnagClient) {
        setBugsnagUser(Parse.User.current());
        globalThis.bugsnagClient.notify(e);
    }
}
export function getNoImageURL() {
    return "images/no-image.jpg";
}
export function showModal(headerHTML, bodyHTML, cancelButtonText, actionButtonText, actionFunc, options) {
    options = options || {};
    const modalWrapper = document.getElementById('modal-wrapper');
    modalWrapper.innerHTML = generateModalHTML(headerHTML, bodyHTML, cancelButtonText, actionButtonText);
    const modal = document.getElementById('modal');
    const modalBackdrop = document.getElementById('modal-backdrop');
    modal.querySelector('.modal-title').innerHTML = headerHTML;
    modal.querySelector('.modal-body').innerHTML = bodyHTML;
    const cancelButton = modal.querySelector('#modal_cancel');
    cancelButton.textContent = cancelButtonText || "Close";
    cancelButton.addEventListener('click', function (e) {
        hideModal(modal);
        modalBackdrop.remove();
        modal.remove();
    });
    const actionButton = modal.querySelector('#modal_action');
    if (actionButtonText != null) {
        actionButton.textContent = actionButtonText;
        actionButton.addEventListener('click', function (e) {
            if (actionFunc && actionFunc(e) !== false) {
                hideModal(modal);
                modal.remove();
            }
        });
    }
    modal.classList.add('show');
    return modal;
}
function hideModal(modal) {
    modal.classList.remove('show');
}
function generateModalHTML(headerHTML, bodyHTML, cancelButtonText, actionButtonText) {
    const cancelButtonHTML = '<button type="button" class="btn btn-secondary" data-dismiss="modal" id="modal_cancel">' + (cancelButtonText || "Close") + '</button>';
    const actionButtonHTML = actionButtonText ? '<button type="button" class="btn btn-primary" id="modal_action">' + actionButtonText + '</button>' : '';
    return `
  <div class="modal-backdrop" id="modal-backdrop"></div>
    <div class="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">${headerHTML}</h5>
          </div>
          <div class="modal-body">${bodyHTML}</div>
          <div class="modal-footer">
            ${cancelButtonHTML}
            ${actionButtonHTML}
          </div>
        </div>
      </div>
    </div>
  `;
}
export function getStripePublicKey() {
    //console.log('get cached Stripe public key:', _cachedStripePublicKey);
    return _cachedStripePublicKey;
}
export function useRegistrationCodes(section) {
    if (section && !_.isEmpty(section) && section.get("authType")) {
        return section.get("authType") == "CODES";
    }
    return Parse.Config.current().get("useRegistrationCodes");
}
export function sendEventToIntercom(eventName, eventInfo) {
    if (globalThis.Intercom) {
        globalThis.Intercom('trackEvent', eventName, eventInfo);
    }
}
export function attachIntercom() {
    Parse.Config.get().then(function (config) {
        const intercomAppId = config.get("intercomAppId");
        if (!intercomAppId) {
            return;
        }
        function bootIntercom() {
            const user = new globalThis.Website.Models.User(Parse.User.current());
            globalThis.Intercom('boot', {
                app_id: intercomAppId,
                name: user.fullName(),
                email: user.getEmail(),
                user_id: user.id,
                phone: user.phone(),
                created_at: user.createdAtTimestamp(),
                "band_name": config.get("bandName"),
                "order_count": user.getOrderCount(),
                "roles": user.roles(),
                "gender": user.gender(),
                "country": user.country(),
                "address": user.address(),
                "profile": config.get("portalURL") + "/#users/" + user.id,
                "stripe_customer_id": user.stripeCustomerId(),
                "avatar": {
                    type: "avatar",
                    image_url: user.image()
                }
            });
        }
        const w = globalThis;
        const ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            // ic('update', intercomSettings);
            bootIntercom();
        }
        else {
            const d = document;
            const i = function () {
                // eslint-disable-next-line prefer-rest-params
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            // eslint-disable-next-line no-inner-declarations
            function l() {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + intercomAppId;
                const x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                return s;
            }
            if (w.attachEvent) {
                w.attachEvent('onload', l);
            }
            else {
                w.addEventListener('load', l, false);
            }
            if (document.readyState === "complete") {
                l().onload = function () {
                    bootIntercom();
                };
            }
        }
    });
}
export function setMetaTags({ title = 'Playmas | Storefront', description = 'Playmas is a powerful carnival management platform designed for mas camps and masqueraders.', keywords = 'playmas, mas', image = 'images/playmas-color.png' }) {
    useHead({
        title: title,
        meta: [
            { name: 'description', content: description },
            { name: 'keywords', content: keywords },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: image },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'PlayMas Storefront' },
            { property: 'og:locale', content: 'en_US' },
            { property: 'twitter:image', content: image },
            { property: 'twitter:site', content: '@playmas' },
            { property: 'twitter:creator', content: '@playmas' },
            { property: 'twitter:title', content: title },
            { property: 'twitter:description', content: description },
        ]
    });
}
