//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_uMOHxhsb4rlYU4cioB0Et9TRicVLQz9TxhXEZH5K8WX", { api_host: "https://app.posthog.com" }
    );

    // Opt out of capturing events if not on the production domain or staging site
    if(!location.hostname.includes("playmas.app") || location.hostname === 'staging.playmas.app') {
      posthog.opt_out_capturing();
    }
  },
};