<template>
  <div>
    <div id="contact-band" @click="showContactModal" class="contact-container" data-extra-key="widget"
      data-extra-value="bottom-right"></div>
    <CartExpiryStoreMessage id="cart-expiry-message" />
    <Nav v-if="data" :bandName="data.bandName" :useEventCatalog="data.useEventCatalog"
      :showCommitteeLinks="data.showCommitteeLinks" :enableWatch="data.enableWatch" :enableResale="data.enableResale"
      :showApplicationsNav="data.showApplicationsNav" :showApplications="data.showApplications"
      :showRegistrationCodesNav="data.showRegistrationCodesNav" :enableNewCart="data.enableNewCart" />
    <div id="main" class="container">
      <slot></slot>
    </div>
    <Footer />
    <ContactModal :modalId="contactModalId" />
    <CartSideBar />
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import ContactModal from '../components/Modals/ContactModal.vue';
import CartSideBar from '../components/CartSideBar.vue';
import CartExpiryStoreMessage from '../components/Cart/CartExpiryStoreMessage.vue';
import Footer from '../components/Footer.vue';
import { getAppConfig, useRegistrationCodes, attachIntercom } from '../helpers/utils';

export default {
  components: {
    ContactModal,
    CartExpiryStoreMessage,
    CartSideBar,
    Nav,
    Footer,
  },
  data () {
    return {
      data: {},
      contactModalId: 'contact-modal'
    }
  },
  methods: {
    showContactModal (e) {
      e.preventDefault();
      this.$bvModal.show(this.contactModalId)
    },
  },
  async created () {
    this.$store.dispatch('entitlements/getEntitlements');
    this.$store.dispatch('cart/getCart');
    const user = Parse.User.current();
    this.data = {
      user: user,
      showRegistrationCodesNav: false,
      enableResale: false,
      showCommitteeLinks: false,
      showApplications: false,
      bandName: ''
    };

    if (this.$paidutils.isCommittee(user)) {
      const promises = [getAppConfig(), this.$paidutils.getAppSettings(), Parse.Cloud.run("anySectionsUseCodes")];

      const results = await Promise.all(promises);
      const showCodesNav = (useRegistrationCodes() || results[2]) && this.$paidutils.isCommittee(user);
      const settings = results[1];
      const showCommitteeLinks = settings.useCommitteeLinks && this.$paidutils.isCommittee(user);
      this.data = {
        user: user,
        showRegistrationCodesNav: showCodesNav,
        showCommitteeLinks: showCommitteeLinks,
        showApplications: true,
        enableResale: settings.enableResale,
        enableWatch: settings.enableWatch,
        enableNewCart: settings.enableNewCart,
        bandName: settings.band_name,
        useEventCatalog: settings.useEventCatalog
      };
      attachIntercom();
    }
    else {
      const settings = await this.$paidutils.getAppSettings();
      this.data.enableResale = settings.enableResale;
      this.data.enableWatch = settings.enableWatch;
      this.data.enableNewCart = settings.enableNewCart;
      this.data.bandName = settings.band_name;
      this.data.useEventCatalog = settings.useEventCatalog;
      this.data.showApplications = false;
      attachIntercom();
    }
  },
};
</script>