const Parse = globalThis.Parse;
export const ItemTemplate = Parse.Object.extend("ItemTemplate", {
    defaults: {
        imgUrl: "",
        desc: "",
        name: "",
    },
    name: function () {
        return this.get("name");
    },
    desc: function () {
        return this.get("desc");
    },
    hasImage: function () {
        const obj = this.get("image");
        if (obj)
            return true;
        return false;
    },
    imageUrl: function () {
        let obj = this.get("image");
        if (obj)
            obj = obj._url;
        return obj;
    },
    thumbSquare: function () {
        let obj = this.get("thumb_sq");
        if (obj)
            obj = obj._url;
        return obj;
    },
    thumbURL: function () {
        let obj = this.get("thumb_sm");
        if (obj)
            obj = obj._url;
        return obj;
    },
    options: function () {
        return this.itemOptions;
    },
    initialize: function () {
        this.itemOptions = [];
    },
    parse: function (response, options) {
        return response;
    }
});
