import './helpers/initializeParse';
import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes, faQuestion, faPencilAlt, faBarcode, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BVConfigPlugin } from 'bootstrap-vue';
import { BootstrapVue } from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import PrismicVue from 'prismic-vue';
import VueSocialSharing from 'vue-social-sharing';
import VueTelInput from 'vue-tel-input';
import vSelect from 'vue-select';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueScreen from 'vue-screen';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import VueLazyload from "vue-lazyload";
import VueLodash from 'vue-lodash';
import VueLocalStorage from 'vue-localstorage';
import lodash from 'lodash';
import App from './App.vue';
import store from './store/index';
import filterFunctions from './filters';
import linkResolver from './linkResolver';
import router from './router/router';
import { subscribeToLogoutEvent } from "./helpers/handleLogoutEvent";
import paidutils from './helpers/paidutils';
import ParseModels from './models';
import 'moment-timezone';
import posthogPlugin from "./plugins/posthog";
import { createHead, VueHeadMixin } from '@unhead/vue';
const head = createHead();
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import 'vueperslides/dist/vueperslides.css';
const Vue = createApp(App);
Vue.config.performance = true;
Vue.config.globalProperties.$filters = filterFunctions;
library.add(faCheck, faTimes, faQuestion, faBarcode, faBell, faPencilAlt);
Vue.component('v-select', vSelect);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('BootstrapVue', {
    extends: BootstrapVue,
    compatConfig: {
        MODE: 3,
        INSTANCE_SCOPED_SLOTS: true,
        INSTANCE_LISTENERS: true,
        COMPONENT_FUNCTIONAL: true,
        COMPONENT_V_MODEL: true,
        CUSTOM_DIR: true,
        OPTIONS_BEFORE_DESTROY: true,
        RENDER_FUNCTION: true,
        ATTR_FALSE_VALUE: true,
        INSTANCE_EVENT_EMITTER: true,
    }
});
Vue.use(BVConfigPlugin, { bvToast: { autoHideDelay: 6000 } });
Vue.use(BootstrapVue);
Vue.use(VueScreen, 'bootstrap');
Vue.use(PortalVue);
Vue.use(VueLazyload);
Vue.use(VueLocalStorage);
Vue.use(PrismicVue, { endpoint: 'https://mcarnivalmanager-website.cdn.prismic.io/api/v2', linkResolver });
Vue.use(VueSocialSharing);
Vue.use(VueTelInput);
Vue.use(VueFacebookPixel);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(paidutils);
Vue.use(posthogPlugin);
Vue.mixin(VueHeadMixin);
Vue.use(head);
global.Website = { Models: ParseModels };
Vue.use(router);
Vue.use(store);
setTimeout(subscribeToLogoutEvent, 2000);
Vue.mount('#app');
global.Vue = Vue;
