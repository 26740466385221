<template>
  <b-overlay :show="loading" variant="white" opacity="0.75">
    <li class="d-flex mb-4 cart-item">
      <img :src="cartItem.thumbUrl" class="rounded mr-3" :alt="cartItem.package.name">
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <p class="my-0 mr-auto">{{ cartItem.section.name }} {{ cartItem.package.name }}</p>
          <a v-b-tooltip.hover title="Remove" v-if="!readonly" href="#" @click.prevent="removeCartItem" class="text-danger ml-4"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
        </div>
        <div>
          <p v-if="cartItem.description.length > 1" class="text-muted small my-0 ">{{ cartItem.description }}</p>
          <div class="d-flex align-self-center justify-content-end">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center">
                <small>{{ cartItem.totalCost > 0 ? formatMoney(cartItem.totalCost, cartItem.currency) : 'Free' }}</small>
                <small v-if="cartItem.discount > 0" class="text-muted ml-3">{{ formatMoney(cartItem.discount, cartItem.currency) }} off</small>
              </div>
              <div>
                <span v-for="entitlement in cartItem.entitlementInstances" :key="entitlement.id">
                  <span class="badge badge-dark mr-1" v-if="!entitlement.isPurchaseRightEntitlement">
                    {{ entitlement.template.name || entitlement.template.grantCode }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Package owner form -->
      <PackageOwnerForm ref="packageOwnerForm" v-if="!hideExtraFormFields && enablePackageOwners" @errorEmmited="(value) => $emit('errorEmmited', value)" />
      </div>
    </li>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import { getNoImageURL } from '../helpers/utils'
import PackageOwnerForm from './PackageOwnerForm.vue'

export default {
  name: "CartItem",
  props: {
    cartItem: Object,
    readonly: {
      required: false,
      type: Boolean
    },
    hideExtraFormFields: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PackageOwnerForm
  },
  data() {
    return {
      lastSavedQuantity: this.cartItem.quantity,
      changingQty: false,
      loading: false,
      changeQuantityDebounced: _.debounce(this.changeQuantity, 300, {leading: true}),
    }
  },
  methods: {
    getThumbnail(product) {
      console.log(product)
      let thumbnail = getNoImageURL()

      // get most appropriate size of image
      if(product && product.thumbnail_small) {
        thumbnail = product.thumbnail_small._url
      } else if(product && product.thumbnail) {
        thumbnail = product.thumbnail._url
      }

      return thumbnail
    },
    formatMoney(amount, currency) {
      return this.$filters.formatMoney(amount, currency)
    },
    async changeQuantity (newQty) {
      if(this.lastSavedQuantity !== newQty) {
        this.changingQty = true
        try {
          await this.$store.dispatch('cart/changeCartItemQuantity', {rawCartItem: this.cartItem.rawItem, quantity: newQty})
          this.lastSavedQuantity = newQty
        } catch(error) {
          // eslint-disable-next-line vue/no-mutating-props
          this.cartItem.quantity = this.lastSavedQuantity
          this.$bvToast.toast(error.message, {variant: "danger"});
        }
        this.changingQty = false
      }
    },
    async removeCartItem () {
      this.loading = true
      try {
        await this.$store.dispatch('cart/removeItem', this.cartItem.rawItem)
        const removeFromCartParams = {
          content_ids: [this.cartItem.package.objectId],
          content_name: this.cartItem.package.name,
          value: this.totalCost,
          currency: this.cartItem.currency,
          quantity: this.cartItem.quantity
        }
        this.$posthog.capture('RemoveFromCart', removeFromCartParams)
        this.$analytics.fbq.event('RemoveFromCart', removeFromCartParams)
      } catch (error) {
        this.$bvToast.toast(error.message, {variant: "danger"});
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('entitlements', ['paymentEntitlements']),
    ...mapGetters('settings', ['enablePackageOwners']),
    totalCost () {
      const pctDiscount = 1 - this.cartItem.discountPct / 100
      const totalCost = (this.cartItem.quantity * this.cartItem.totalCost) * pctDiscount - this.cartItem.discountFixed
      return totalCost
    }
  },
}
</script>

<style scoped>
.quantity {
  position: relative;
  top: -2px;
  white-space: nowrap;
}
img {
  object-fit: cover;
  height: 84px;
  aspect-ratio: 5 / 6;
}
</style>