var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getQueryString, getNoImageURL } from './utils';
import Cookies from 'js-cookie';
import _ from 'lodash';
const Parse = globalThis.Parse;
export function getLargeImageURL(parsePackage) {
    let imageUrl = getNoImageURL();
    if (parsePackage) {
        if (parsePackage.get('thumbnail_large')) {
            imageUrl = parsePackage.get('thumbnail_large')._url;
        }
        else if (parsePackage.get('image')) {
            imageUrl = parsePackage.get('image')._url;
        }
    }
    return imageUrl;
}
export function getPackageImages(parsePackageId) {
    return __awaiter(this, void 0, void 0, function* () {
        const query = new Parse.Query('PackageImage');
        query.equalTo('package', getParsePointer('Package', parsePackageId));
        query.ascending('order');
        return yield query.find();
    });
}
export function getSmallImageURL(parsePackage) {
    let imageUrl = getNoImageURL();
    if (parsePackage) {
        if (parsePackage.get('thumbnail_small')) {
            imageUrl = parsePackage.get('thumbnail_small')._url;
        }
        else if (parsePackage.get('thumbnail')) {
            imageUrl = parsePackage.get('thumbnail')._url;
        }
    }
    return imageUrl;
}
export function getItemTempatesForPackage(eventPackageId) {
    return __awaiter(this, void 0, void 0, function* () {
        const itemTemplates = [];
        const PackageMembership = Parse.Object.extend("PackageMembership");
        const query = new Parse.Query(PackageMembership);
        query.equalTo("package", getParsePointer('Package', eventPackageId));
        query.notEqualTo("deleted", true);
        query.notEqualTo('type', 'addon');
        query.include("itemTemplate");
        const ItemTemplate = Parse.Object.extend("ItemTemplate");
        const innerQuery = new Parse.Query(ItemTemplate);
        innerQuery.notEqualTo("deleted", true);
        innerQuery.notEqualTo('type', 'addon');
        query.matchesQuery("itemTemplate", innerQuery);
        const memberships = yield query.find();
        const promises = [];
        memberships.forEach((membership) => __awaiter(this, void 0, void 0, function* () {
            const itemtemplate = membership.get("itemTemplate");
            const OptionMembership = Parse.Object.extend("OptionMembership");
            const optionMembershipQuery = new Parse.Query(OptionMembership);
            optionMembershipQuery.include("option");
            optionMembershipQuery.equalTo("itemTemplate", itemtemplate);
            const promise = optionMembershipQuery.find();
            promises.push(promise);
            promise.then(function (optionMemberships) {
                optionMemberships = _.orderBy(optionMemberships, [(option) => option.get('sortOrder'), (option) => option.get("option").get("createdAt")], ['asc', 'desc']);
                optionMemberships.forEach((optionMembership) => {
                    itemtemplate.itemOptions.push(optionMembership.get("option"));
                });
            });
            itemtemplate.showDescription = itemtemplate.get('showDescription');
            itemtemplate.desc = itemtemplate.get('desc');
            itemtemplate.sortOrder = membership.get('sortOrder');
            itemTemplates.push(itemtemplate);
        }));
        yield Promise.all(promises);
        return itemTemplates;
    });
}
export function getAddOnsForPackage(eventPackage) {
    return __awaiter(this, void 0, void 0, function* () {
        const ItemTemplate = Parse.Object.extend("ItemTemplate");
        const AddOnMembership = Parse.Object.extend("PackageMembership");
        const innerQuery = new Parse.Query(ItemTemplate);
        innerQuery.notEqualTo("deleted", true);
        innerQuery.equalTo('type', 'addon');
        const query = new Parse.Query(AddOnMembership);
        query.equalTo("package", eventPackage);
        query.include("itemTemplate");
        query.equalTo('type', 'addon');
        query.notEqualTo("deleted", true);
        const addonsForThisPackage = yield query.find();
        return addonsForThisPackage;
    });
}
export function getOptionsForAddOns(addOns) {
    return __awaiter(this, void 0, void 0, function* () {
        const promises = [];
        addOns.forEach((membership) => {
            const itemtemplate = membership.get("itemTemplate");
            const OptionMembership = Parse.Object.extend("OptionMembership");
            const optionMembershipQuery = new Parse.Query(OptionMembership);
            optionMembershipQuery.include("option");
            optionMembershipQuery.equalTo("itemTemplate", itemtemplate);
            const promise = optionMembershipQuery.find();
            promises.push(promise);
            promise.then((optionMemberships) => {
                optionMemberships = _.orderBy(optionMemberships, [(option) => option.get('sortOrder'), 'name'], ['asc', 'asc']);
                if (itemtemplate.itemOptions.length == 0) { // check to see if we have already retrieved options for this
                    optionMemberships.forEach((optionMembership) => {
                        itemtemplate.itemOptions.push(optionMembership.get("option"));
                    });
                }
            });
        });
        yield Promise.all(promises);
        return addOns;
    });
}
export function isSoldOut(parsePackage) {
    if (!parsePackage) {
        return false;
    }
    const qtyAvailable = parsePackage.get('qtyAvailable');
    const qtySold = parsePackage.get('qtySold');
    const soldOutOverride = parsePackage.get('soldOutOverride');
    if (!qtyAvailable || qtyAvailable === 0) { // unlimited inventory
        return soldOutOverride;
    }
    return (qtySold >= qtyAvailable) || soldOutOverride;
}
export function captureAttribution() {
    const queryString = getQueryString();
    if (queryString["source"]) {
        const expireInDays = 2;
        Cookies.set('source', queryString["source"], { expires: expireInDays });
    }
}
export function calculateDiscounts(entitlements) {
    const percentageDiscounts = [];
    const fixedDiscounts = [];
    for (const entitlement of entitlements) {
        if (entitlement.template.type === 'DISCOUNT_PCT') {
            percentageDiscounts.push(Number(entitlement.template.right.amount));
        }
        else if (entitlement.template.type === 'DISCOUNT_FIXED') {
            fixedDiscounts.push(Number(entitlement.template.right.amount));
        }
    }
    const discountPct = _.sum(percentageDiscounts);
    const discountFixed = _.sum(fixedDiscounts);
    return [discountPct, discountFixed];
}
export function captureAffiliate({ packageId, eventId, sectionId }) {
    const queryString = getQueryString();
    const affiliateLink = queryString["al"];
    if (affiliateLink && Cookies.get('affiliate') !== affiliateLink) {
        const expireInDays = 2;
        Cookies.set('affiliate', affiliateLink, { expires: expireInDays });
        Parse.Cloud.run('affiliateLinkClick', { linkId: affiliateLink, packageId, eventId, sectionId });
        removeQueryParam('al');
    }
    return affiliateLink;
}
function removeQueryParam(paramToRemove) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete(paramToRemove);
    const newUrl = queryParams.toString() ? `${window.location.pathname}?${queryParams.toString()}` : window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
}
export function getParsePointer(className, objectId) {
    return {
        __type: 'Pointer',
        className,
        objectId
    };
}
