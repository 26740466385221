var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DataService from "../../services/DataService";
import _ from 'lodash';
const ds = DataService.getInstance();
// initial state
const state = {
    entitlements: []
};
// getters
const getters = {
    entitlements: (_state) => _state.entitlements,
    paymentEntitlements: (_state, getters) => {
        return _state.entitlements.filter(entitlement => entitlement.template.consumedOn === 'PAYMENT');
    },
    nonPurchaseRightEntitlements: (_state, getters, rootState) => {
        const nonPurchaseEntititlementFilteredArray = _state.entitlements.filter(entitlement => entitlement.template.type === 'PURCHASE');
        const nonPurchaseRightEntitlement = _.first(nonPurchaseEntititlementFilteredArray);
        return rootState.order.entitlements.filter(entitlementId => entitlementId !== (nonPurchaseRightEntitlement === null || nonPurchaseRightEntitlement === void 0 ? void 0 : nonPurchaseRightEntitlement.id));
    },
};
// actons
const actions = {
    getEntitlements: ({ commit, getters }, packageId) => __awaiter(void 0, void 0, void 0, function* () {
        const entitlements = yield ds.getEntitlements({
            filters: {
                remainingGreaterThanZero: true,
                onlyConsumedOnPurchase: true
            },
            packageId
        });
        commit('entitlements', entitlements);
    }),
    grantEntitlmentsWithCode: ({ commit, getters, dispatch }, { code, packageId }) => __awaiter(void 0, void 0, void 0, function* () {
        yield ds.grantEntitlmentsWithCode(code);
        dispatch('getEntitlements', packageId);
    }),
};
// mutations
const mutations = {
    entitlements(state, entitlements) {
        const newEntitlementState = [];
        entitlements.forEach((entitlement) => {
            const template = entitlement.get('template');
            newEntitlementState.push({
                id: entitlement.id,
                applicant: entitlement.get('applicant'),
                state: entitlement.get('state'),
                approved: entitlement.get('approved'),
                requested: entitlement.get('requested'),
                used: entitlement.get('used'),
                member: entitlement.get('member'),
                template: {
                    id: template.id,
                    type: template.get('type'),
                    grantByCode: template.get('grantByCode'),
                    memberIssued: template.get('memberIssued'),
                    publicApply: template.get('publicApply'),
                    consumedOn: template.get('consumedOn'),
                    grantCode: template.get('grantCode'),
                    name: template.get('name') || template.get('grantCode'),
                    enforceMemberLimits: template.get('enforceMemberLimits'),
                    right: template.get('right'),
                    rights: template.get('rights'),
                    granted: template.get('granted'),
                    limit: template.get('limit'),
                },
            });
        });
        state.entitlements = newEntitlementState;
    },
    addEntitlement(state, entitlements) {
        const grantedEntitlements = [];
        entitlements.forEach((entitlement) => {
            const template = entitlement.get('template');
            grantedEntitlements.push({
                id: entitlement.id,
                applicant: entitlement.get('applicant'),
                state: entitlement.get('state'),
                approved: entitlement.get('approved'),
                requested: entitlement.get('requested'),
                used: entitlement.get('used'),
                member: entitlement.get('member'),
                template: {
                    id: template.id,
                    grantByCode: template.get('grantByCode'),
                    memberIssued: template.get('memberIssued'),
                    publicApply: template.get('publicApply'),
                    consumedOn: template.get('consumedOn'),
                    grantCode: template.get('grantCode'),
                    name: template.get('name') || template.get('grantCode'),
                    enforceMemberLimits: template.get('enforceMemberLimits'),
                    rights: template.get('rights'),
                    granted: template.get('granted'),
                    limit: template.get('limit'),
                },
            });
            state.entitlements = state.entitlements.concat(grantedEntitlements);
        });
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
