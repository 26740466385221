<template>
  <footer class="branding">
    <a class="branding" href="https://www.playmas.app" target="_blank">
      <img src="images/playmas-color.png">
    </a>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }

</script>
