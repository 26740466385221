import moment from 'moment';
const Parse = globalThis.Parse;
export const Payment = Parse.Object.extend("Payment", {
    initialize: function () {
    },
    amount: function () {
        const marketplaceCost = this.get("marketplaceCost") ? this.get("marketplaceCost") : 0;
        const amount = this.get("amount");
        return paidutils.formatMoney(amount + marketplaceCost, this.currency());
    },
    fee: function () {
        return this.get('fee') ? paidutils.formatMoney(this.get('fee'), this.currency()) : '-';
    },
    marketplaceCost: function () {
        return this.get('marketplaceCost') ? paidutils.formatMoney(this.get("marketplaceCost"), this.currency()) : '-';
    },
    bandOfflineFee: function () {
        return this.get("bandOfflineFee") ? paidutils.formatMoney(this.get("bandOfflineFee"), this.currency()) : '-';
    },
    taxes: function () {
        return this.get('taxes') ? paidutils.formatMoney(this.get("taxes"), this.currency()) : '-';
    },
    madeBy: function () { return this.get("madeBy"); },
    type: function () { return globalThis.getPaymentMethod(this.get("type")); },
    note: function () { return this.get("note"); },
    paymentDateTime: function () { return moment(this.createdAt).format("YYYY/DD/MM hh:mmA"); },
    prettyDate: function () { return moment(this.createdAt).format("D MMM YYYY"); },
    prettyTime: function () { return moment(this.createdAt).format("h:mmA"); },
    fromNowDate: function () { return moment(this.createdAt).fromNow(); },
    onlineFee: function () {
        return this.get('fee') ? paidutils.formatMoney(this.get('fee'), this.currency()) : null;
    },
    currency: function () {
        return this.get('currency') ? this.get('currency') : paidutils.getCurrencyCode();
    }
});
