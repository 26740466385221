import { PusherService } from '../services/PusherService';
import DataService from '../services/DataService';
const ds = DataService.getInstance();
function handleLogout(sessionToken) {
    const user = ds.currentUser();
    if (user && user.getSessionToken() === sessionToken) {
        alert('You have exceeded the number of allowed sessions so will be logged out from here');
        ds.logout();
        window.location.reload();
    }
}
export function subscribeToLogoutEvent() {
    const user = ds.currentUser();
    if (user) {
        console.log(`Subscribing to the channel for user: ${user.id}`);
        const channel = PusherService.getInstance().subscribeToChannel(user.id);
        PusherService.getInstance().subscribeToEvent(channel, PusherService.logoutEventName(), handleLogout);
    }
}
