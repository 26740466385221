const Parse = globalThis.Parse;
export const Section = Parse.Object.extend("Section", {
    initialize: function () {
    },
    defaults: {
        name: "",
        image: null,
        description: ""
    },
    name: function () {
        return this.get("name");
    },
    desc: function () {
        return this.get("desc");
    },
    presentation: function () {
        return this.get("presentation");
    },
    mealsDisabled: function () {
        return this.get("mealsDisabled");
    },
    parse: function (response, options) {
        return response;
    }
});
