<template>
  <div>
    <FullScreenLoader :isLoading="fullScreenLoading" />
    <b-sidebar ref="cartSidebar" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" title="Your Cart"
      width="26rem" body-class="p-3" right footer-class="p-3" shadow backdrop @shown="initialize" lazy>
      <template #header="{ hide }">
        <div class="sheet-container shadow-sm">
          <div class="header">
            <h4 class="pb-0 mb-0">Your Cart</h4>
            <button @click="hide" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </template>
      <template #default>
        <Cart :hideExtraFormFields="true" :refreshCart="refreshCart" />
      </template>
      <template #footer="{ hide }">
        <div v-if="cartDiscount > 0" class="d-flex justify-content-between w-100">
          <p class="text-muted mb-2">Total discount:</p>
          <p class="text-muted mb-2">{{ $filters.formatMoney(cartDiscount, currency) }}</p>
        </div>
        <div class="d-flex justify-content-between w-100">
          <p class="text-muted">Total:</p>
          <p class="text-danger font-weight-bold">{{ $filters.formatMoney(cartTotal, currency) }}</p>
        </div>

        <button type="button" :disabled='cartItems.length == 0' class="btn btn-block checkout-btn btn-primary"
          @click="() => checkout(hide)">
          <i class="fa fa-lock mr-1" aria-hidden="true"></i> {{ freePurchase ? 'Free Purchase' : 'Checkout' }}
        </button>
        <div v-if="isCartExpiryTimeEnabled" class="mt-3">
          <p v-if="countdown > 0" class="text-center text-danger small">Your cart expires in {{ formattedCountdown }}</p>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment';
import Cart from '../components/Cart.vue';
import FullScreenLoader from '../components/FullScreenLoader'
import CartExpirationTimer from '../helpers/cartTimer';

export default {
  data () {
    return {
      itemCount: 0,
      fullScreenLoading: false,
      refreshCart: 0,
      countdown: 0,
    }
  },
  components: {
    Cart,
    FullScreenLoader
  },
  watch: {
    cartItems: {
      handler (cartItems) {
        if (cartItems.length === 0) {
          CartExpirationTimer.getInstance().reset();
          this.countdown = 0;
        }
      },
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (id, opened) => {
      if (id === 'sidebar-no-header' && opened) {
        CartExpirationTimer.getInstance().subscribe(this.$options.name, ({countdown, formatted}) => {
          this.countdown = countdown;
          this.formattedCountdown = formatted;
        });
      }
    });
  },
  methods: {
    async checkout (hideSidebar) {
      if (this.freePurchase) {
        this.fullScreenLoading = true
        try {
          await this.$store.dispatch('cart/freePurchase')
          this.$router.push('/orders')
          hideSidebar();
        } catch (error) {
          this.$bvToast.toast(error.message, { variant: 'danger' });
        }
        this.fullScreenLoading = false
      } else {
        this.$router.push('/checkout')
        hideSidebar();
      }
    },
    async initialize () {
      this.refreshCart = _.random(100);
    }
  },
  computed: {
    ...mapGetters('cart', [
      'cartId',
      'cartItems',
      'currency',
      'cartTotal',
      'cartDiscount'
    ]),
    ...mapGetters('settings', ['isCartExpiryTimeEnabled']),
    freePurchase () {
      return this.cartTotal == 0 && this.cartItems.length > 0
    }
  }
}

</script>

<style>
.sheet-container .header {
  top: 0;
  width: 100%;
  padding: 2rem 1rem;
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-cart {
  padding: 10px;
}

.btn-block,
.checkout-btn {
  padding: 0.8rem 0 !important;
}

.close {
  margin: 0 !important;
}

.b-sidebar>.b-sidebar-header {
  display: block !important;
  padding: 0 !important;
}

@media (max-width:480px) {
  .b-sidebar {
    width: 100% !important;
  }
}
</style>