const Parse = globalThis.Parse;
export const OptionInstance = Parse.Object.extend("ItemInstanceOption", {
    url: '',
    initialize: function () {
    },
    defaults: {},
    name: function () {
        return this.get("optionName");
    },
    value: function () {
        return this.get("optionValue");
    },
    parse: function (response, options) {
        return response;
    }
});
