import moment from 'moment';
const Parse = globalThis.Parse;
export const Application = Parse.Object.extend("EntitlementInstance", {
    initialize: function () {
    },
    name: function () {
        return this.get("applicant").get("firstName") + " " + this.get("applicant").get("lastName");
    },
    memberName: function () { return this.get("member") ? this.get("member").fullName() : ""; },
    country: function () { return this.get("applicant").get("country"); },
    phone: function () { return this.get("applicant").get("phone"); },
    email: function () { return this.get("applicant").get("username"); },
    numRequested: function () { return this.get("requested"); },
    numApproved: function () { return this.get("approved"); },
    numUsed: function () { return this.get("used"); },
    numRemaining: function () { return Math.max(0, this.get("approved") - this.get("used")); },
    spotsToApprove: function () { return this.numRequested() - this.numApproved(); },
    state: function () { return this.get("state"); },
    pending: function () {
        return this.get("state") === "NOT_REVIEWED" || this.get("state") === "REVIEWED";
    },
    reviewed: function () {
        return this.get("state") === "REVIEWED";
    },
    notReviewed: function () { return this.get("state") === "NOT_REVIEWED"; },
    fromNowDate: function () { return moment(this.createdAt).fromNow(); },
    template: function () { return this.get("template"); },
    packageIdForPurchaseRight: function () {
        const rights = this.get('template').get('rights') || [];
        const purchaseRight = rights.filter(function (right) {
            return right.split(':')[0] === 'PURCHASE';
        })[0];
        const packageId = purchaseRight && purchaseRight.split(':')[1];
        return packageId;
    },
    templateName: function () {
        return this.get('template') && this.get('template').get('name');
    },
    parse: function (response, options) {
        return response;
    }
});
