import Pusher from 'pusher-js';
Pusher.logToConsole = false;
export class PusherService {
    constructor() {
        // @ts-ignore
        this.pusher = new Pusher('5316113a9998a6eff435', {
            cluster: 'mt1',
            forceTLS: true
        });
    }
    static getInstance() {
        if (!PusherService.instance) {
            PusherService.instance = new PusherService();
        }
        return PusherService.instance;
    }
    subscribeToChannel(channelName) {
        return this.pusher.subscribe(channelName);
    }
    subscribeToEvent(channel, eventName, callback) {
        channel.bind(eventName, callback);
    }
    unsubscribeFromChannel(channel) {
        channel.unsubscribe();
    }
    unsubscribeFromAllChannel() {
        this.pusher.allChannels().forEach(channel => channel.unsubscribe());
    }
    static logoutEventName() {
        return `logout`;
    }
    static newWatchMessageEventName() {
        return `new-message`;
    }
}
