<template>
  <div v-if="isLoading" class="loader">Loading...</div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isLoading: false
  }
}
</script>

<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(86,124,229, 0.2);
  border-right: 1.1em solid rgba(86,124,229, 0.2);
  border-bottom: 1.1em solid rgba(86,124,229, 0.2);
  border-left: 1.1em solid #567ce5;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
</style>