import _ from 'lodash';
const Parse = globalThis.Parse;
export const ItemOptions = Parse.Object.extend("Option", {
    initialize: function () {
    },
    defaults: {},
    name: function () {
        return this.get("name");
    },
    valueArray: function () {
        const options = [];
        const name = this.name();
        _.each(this.get("values"), function (value) {
            options.push({
                display: name + ": " + value,
                value: value
            });
        });
        return options;
    },
    parse: function (response, options) {
        return response;
    }
});
