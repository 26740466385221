const Parse = globalThis.Parse;
export const AddOnMembership = Parse.Object.extend("PackageMembership", {
    initialize: function () {
    },
    itemTemplate: function () { return this.get("itemTemplate") || new globalThis.Website.Models.ItemTemplate(); },
    cost: function () { return this.get("itemTemplate").get("cost"); },
    package: function () { return this.get("package"); },
    formattedCost: function () {
        return paidutils.formatMoney(this.cost());
    },
    parse: function (response) {
        return response;
    },
    soldout: function () {
        const override = this.get("itemTemplate").get("soldOutOverride");
        let soldout = false;
        const qtyAvailable = this.get("itemTemplate").get('qtyAvailable');
        const limit = this.get("itemTemplate").get('limit');
        if (limit) {
            soldout = this.get("itemTemplate").get("qtySold") >= limit;
        }
        else if (qtyAvailable) {
            soldout = this.get("itemTemplate").get("qtySold") >= qtyAvailable;
        }
        return override || soldout;
    }
});
