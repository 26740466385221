const Parse = globalThis.Parse;
export const PromoCode = Parse.Object.extend("PromoCode", {
    url: '',
    initialize: function () {
    },
    redeemedBy: function () {
        const redeemUser = this.get("redeemedBy");
        if (redeemUser) {
            return redeemUser.shortenedName();
        }
        else {
            return null;
        }
    },
    code: function () { return this.get("code"); },
    presentation: function () {
        let presentation = "Any Presentation";
        if (this.get("presentation")) {
            presentation = this.get("presentation").get("name");
        }
        return presentation;
    },
    defaults: {
        user: null,
        code: "",
        redeemedBy: null
    },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    }
});
