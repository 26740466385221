var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { throttle, reduce, times, flatten, some, values } from 'lodash';
import DataService from '../../services/DataService';
import { calculateDiscounts } from '../../helpers/packages';
import { isPurchaseRightEntitlement } from '../../helpers/entitlements';
const ds = DataService.getInstance();
// initial state
const state = {
    cartItems: [],
    cartId: '',
    currency: '',
    expireTime: null,
    connectedStripeKeyHash: '',
    totalPackageCost: 0,
    totalAddOnCost: 0,
    shippingAddress: null,
    countries: null
};
// getters
const getters = {
    cartItems: (_state) => _state.cartItems,
    cartId: (_state) => _state.cartId,
    expireTime: (_state) => _state.expireTime,
    currency: (_state) => _state.currency.toLowerCase(),
    countries: (_state) => _state.countries,
    connectedStripeKeyHash: (_state) => _state.connectedStripeKeyHash,
    totalPackageCost: (_state) => _state.totalPackageCost,
    totalAddOnCost: (_state) => _state.totalAddOnCost,
    shippingAddress: (_state) => _state.shippingAddress,
    requiresShippingAddress: (_state) => {
        return some(_state.cartItems, item => {
            const methods = flatten(values(item.itemDelivery));
            return methods.includes('shipped');
        });
    },
    cartTotal: (_state) => {
        return reduce(_state.cartItems, (memo, item) => {
            const totalCost = Math.max(0, item.totalCost - item.discount);
            return memo + (item.quantity * totalCost);
        }, 0);
    },
    cartDiscount: (_state) => {
        return reduce(_state.cartItems, (memo, item) => {
            return memo + item.discount;
        }, 0);
    },
};
// actons
const actions = {
    getCart: throttle(({ dispatch, commit, rootGetters }) => __awaiter(void 0, void 0, void 0, function* () {
        const cart = yield ds.getCart();
        if (cart) {
            updateCartState(cart, commit, rootGetters);
        }
        else {
            commit('reset');
        }
    }), 5000, { 'trailing': false }),
    addToCart: ({ commit, rootGetters }, cartItem) => __awaiter(void 0, void 0, void 0, function* () {
        const cart = yield ds.addToCart(cartItem);
        if (cart) {
            const cartItems = cart.prettyCartItems;
            const userEntitlements = rootGetters['entitlements/paymentEntitlements'];
            cartItems.forEach(item => {
                item.entitlementInstances = userEntitlements.filter(e => item.entitlements.includes(e.id));
                const [discountPct, discountFixed] = calculateDiscounts(item.entitlementInstances);
                item.discountPct = discountPct;
                item.discountFixed = discountFixed;
            });
            commit('cartItems', cartItems);
            commit('cartId', cart.cartId);
            commit('currency', cart.currency);
            commit('expireTime', cart.expireTime);
            commit('totalPackageCost', cart.totalPackageCost);
            commit('totalAddOnCost', cart.totalAddOnCost);
            commit('connectedStripeKeyHash', cart.connectedStripeKeyHash);
        }
    }),
    getCartByForce: () => __awaiter(void 0, void 0, void 0, function* () {
        const cart = yield ds.getCart();
        return cart;
    }),
    getCountries: ({ commit }) => __awaiter(void 0, void 0, void 0, function* () {
        const countries = yield ds.getPrintfulCountries();
        commit('countries', countries);
    }),
    removeItem: ({ dispatch, commit, rootGetters }, rawCartItem) => __awaiter(void 0, void 0, void 0, function* () {
        yield ds.removeItemFromCart(rawCartItem);
        commit('removeCartItem', rawCartItem);
        const cart = yield dispatch('getCartByForce');
        cart && (yield updateCartState(cart, commit, rootGetters));
    }),
    changeCartItemQuantity: ({ commit }, { rawCartItem, quantity }) => __awaiter(void 0, void 0, void 0, function* () {
        yield ds.changeCartItemQuantity(rawCartItem, quantity);
        commit('changeCartItemQuantity', { rawCartItem, quantity });
    }),
    freePurchase: ({ commit, getters }) => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: move to server
        const freeCart = getters.cartItems.map((item) => {
            return times(item.quantity, () => {
                let packageId = null;
                if (item.package) {
                    packageId = item.package.objectId || item.package.id;
                }
                let sectionId = null;
                if (item.section) {
                    sectionId = item.section.objectId || item.section.id;
                }
                return {
                    code: null,
                    meal: item.meal,
                    entitlements: item.entitlements,
                    queryString: item.queryString,
                    orderParts: {
                        itemAddOns: item.itemAddOns,
                        itemOptions: item.itemOptions,
                        package_id: packageId,
                        section_id: sectionId
                    }
                };
            });
        });
        const cart = {
            amount: getters.cartTotal,
            cart: flatten(freeCart)
        };
        yield ds.createOrder(cart);
        yield ds.emptyCart();
    }),
    lockCart: ({ dispatch, commit }, cartId) => __awaiter(void 0, void 0, void 0, function* () {
        yield ds.lockCart(cartId);
        commit('reset');
        yield dispatch('getCart');
    }),
};
// mutations
const mutations = {
    reset(state) {
        state.cartItems = [];
        state.cartId = '';
        state.currency = '';
        state.connectedStripeKeyHash = '';
        state.totalPackageCost = 0;
        state.totalAddOnCost = 0;
        state.shippingAddress = null;
    },
    cartItems(state, cartItems) {
        state.cartItems = cartItems;
    },
    cartId(state, cartId) {
        state.cartId = cartId;
    },
    expireTime(state, expireTime) {
        state.expireTime = expireTime;
    },
    currency(state, currency) {
        state.currency = currency;
    },
    totalPackageCost(state, totalPackageCost) {
        state.totalPackageCost = totalPackageCost;
    },
    totalAddOnCost(state, totalAddOnCost) {
        state.totalAddOnCost = totalAddOnCost;
    },
    removeCartItem(state, rawCartItem) {
        console.log('Called removeCartItem');
        let index = -1;
        for (let i = 0; i < state.cartItems.length; i++) {
            const cartItem = state.cartItems[i];
            if (cartItem.rawItem === rawCartItem) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            state.cartItems.splice(index, 1);
        }
    },
    connectedStripeKeyHash(state, connectedStripeKeyHash) {
        state.connectedStripeKeyHash = connectedStripeKeyHash;
    },
    changeCartItemQuantity(state, cartItemQuantity) {
        const { rawCartItem, quantity } = cartItemQuantity;
        const index = state.cartItems.findIndex((item) => item.rawItem == rawCartItem);
        if (index === -1) {
            throw `Cart Item not found`;
        }
        state.cartItems[index].quantity = quantity;
    },
    shippingAddress(state, address) {
        state.shippingAddress = null;
        state.shippingAddress = address;
    },
    countries(state, countries) {
        state.countries = countries;
    },
};
function updateCartState(cart, commit, rootGetters) {
    return __awaiter(this, void 0, void 0, function* () {
        const cartItems = cart.prettyCartItems;
        const userEntitlements = rootGetters['entitlements/paymentEntitlements'];
        cartItems.forEach(item => {
            item.entitlementInstances = userEntitlements.filter(e => item.entitlements.includes(e.id));
            item.entitlementInstances.forEach(entitlement => {
                entitlement.isPurchaseRightEntitlement = isPurchaseRightEntitlement(item.package.id, entitlement);
            });
            const [discountPct, discountFixed] = calculateDiscounts(item.entitlementInstances);
            item.discountPct = discountPct;
            item.discountFixed = discountFixed;
        });
        commit('cartItems', cartItems);
        commit('cartId', cart.cartId);
        commit('currency', cart.currency);
        commit('expireTime', cart.expireTime);
        commit('totalPackageCost', cart.totalPackageCost);
        commit('totalAddOnCost', cart.totalAddOnCost);
        commit('connectedStripeKeyHash', cart.connectedStripeKeyHash);
        return cart;
    });
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
