const Parse = globalThis.Parse;
export const PackageMembership = Parse.Object.extend("PackageMembership", {
    url: '',
    initialize: function () {
    },
    defaults: {
        package: null,
        itemTemplate: null
    },
    validate: function (attrs, options) {
    },
    parse: function (response, options) {
        return response;
    }
});
