<template>
  <div class="cart-skeleton">
    <div class="d-flex">
      <b-skeleton width="58px" height="69px" border-radius="5px" class="m-2"></b-skeleton>
      <div class="mt-3 ml-2">
        <b-skeleton width="20px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
        <div>
          <b-skeleton width="250px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
          <b-skeleton width="250px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <b-skeleton width="58px" height="69px" border-radius="5px" class="m-2"></b-skeleton>
      <div class="mt-3 ml-2">
        <b-skeleton width="20px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
        <div>
          <b-skeleton width="250px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
          <b-skeleton width="250px" height="8px" border-radius="2px" class="m-2"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.cart-skeleton {
  width: 480px;
  height: 180px;
}
</style>