var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const Parse = globalThis.Parse;
export default class DataService {
    constructor() {
        if (DataService._instance) {
            throw new Error('Use getInstance() instead of new.');
        }
        DataService._instance = this;
    }
    static getInstance() {
        return DataService._instance;
    }
    static cloudFunction(funName, params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield Parse.Cloud.run(funName, Object.assign({}, params));
                return response;
            }
            catch (e) {
                if (e.code === 100) {
                    throw 'No Internet...Please try again when online.';
                }
                if (e.code === 209) {
                    Parse.User.logOut();
                    window.location.reload();
                }
                else {
                    throw (e || e.toString());
                }
            }
        });
    }
    //********************************
    // Band's endpoints
    //*******************************/
    getMinimumPayment(items) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getOrderMinDownpayment", { items });
            return response;
        });
    }
    validatePromoCode(code, section) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("IsValidPromoCode", { code, section });
            return response;
        });
    }
    getMarketplaceOrderItemsForOrder(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketplaceItems = yield DataService.cloudFunction("getMarketplaceOrderItemsForOrder", { order_id: orderId });
            return marketplaceItems;
        });
    }
    getOrderForUserWithOrderId(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const order = yield DataService.cloudFunction("getOrderForUserWithOrderId", { order_id: orderId });
            return order;
        });
    }
    getResaleOrderWithOrderId(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const order = yield DataService.cloudFunction("getResaleOrderWithOrderId", { order_id: orderId });
            return order;
        });
    }
    getAdditionalPaymentOptionsForOrder(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const paymentOptions = yield DataService.cloudFunction("getAdditionalPaymentOptionsForOrder", { order_id: orderId });
            return paymentOptions;
        });
    }
    getAddOnOptions(addOn) {
        return __awaiter(this, void 0, void 0, function* () {
            const OptionMembership = Parse.Object.extend("OptionMembership");
            const query = new Parse.Query(OptionMembership);
            query.include("option");
            query.include("itemTemplate");
            query.equalTo("itemTemplate", addOn);
            const options = yield query.find();
            return options;
        });
    }
    getPackageAddOns(packagePointer, PackageMembershipModel) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = new Parse.Query(PackageMembershipModel);
            query.equalTo("package", packagePointer);
            query.include("itemTemplate");
            query.equalTo('type', 'addon');
            query.notEqualTo("deleted", true);
            const packageAddons = yield query.find();
            return packageAddons;
        });
    }
    getGlobalAddOns(PackageMembershipModel) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = new Parse.Query(PackageMembershipModel);
            query.doesNotExist("package");
            query.equalTo("isPublic", true);
            query.include("itemTemplate");
            const globalAddons = yield query.find();
            return globalAddons;
        });
    }
    makeAdditionalPayment(orderId, amount, token) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("makeAdditionalPayment", { order_id: orderId, amount, token });
            return response;
        });
    }
    getCartFees({ subTotal, shippingAddress }) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getCartFees", { subTotal, shippingAddress });
            return response;
        });
    }
    getCartBySession(sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getCartBySession", { sessionId });
            return response;
        });
    }
    lockCart(cartId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("lockCart", { id: cartId });
            return response;
        });
    }
    getPrintfulCountries() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getPrintfulCountries");
            return response;
        });
    }
    addToCart(itemToAdd) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("addToCart", { itemToAdd });
            return response;
        });
    }
    emptyCart() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("emptyCart");
            return response;
        });
    }
    getOrderFees(subTotal, orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getOrderFees", { orderId, subTotal });
            return response;
        });
    }
    createAdditionalPaymentSession(orderId, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("createAdditionalPaymentSession", { order_id: orderId, amount });
            return response;
        });
    }
    extendOrderWithAdditionalPurchase(orderId, amount, token, cartUpdate) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("extendOrderWithAdditionalPurchase", { order_id: orderId, amount, token, cartUpdate });
            return response;
        });
    }
    createExtendOrderWithAdditionalPurchasePaymentSession(orderId, amount, cartUpdate) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("createExtendOrderWithAdditionalPurchasePaymentSession", { order_id: orderId, amount, cartUpdate });
            return response;
        });
    }
    getUser(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const sessionToken = yield DataService.cloudFunction('login', { username, password });
            return Parse.User.become(sessionToken);
        });
    }
    currentUser() {
        return Parse.User.current();
    }
    logout() {
        return Parse.User.logOut();
    }
    requestUserResetPassword(email) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Parse.User.requestPasswordReset(email);
        });
    }
    downloadOrderReceipt(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return DataService.cloudFunction('downloadOrderReceipt', { id });
        });
    }
    downloadOrderTickets(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            return DataService.cloudFunction('downloadOrderTickets', { orderId });
        });
    }
    downloadOrderItemPDF(orderItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            return DataService.cloudFunction('downloadOrderItemPDF', { orderItemId });
        });
    }
    resendReceipt(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("resendReceipt", { order_id: orderId });
            return response;
        });
    }
    addCollectorToOrder(orderId, collector) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("addCollectorToOrder", { order_id: orderId, collector });
            return response;
        });
    }
    userAllowedToCollect(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("userAllowedToCollect", { user });
            return response;
        });
    }
    removeCollectorFromOrder(orderId, collector) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("removeCollectorFromOrder", { order_id: orderId, collector });
            return response;
        });
    }
    getOrdersForUserPaginated(page, perPage) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getOrdersForUser", { page, size: perPage });
            return response;
        });
    }
    getAuthorizedOrderItemsForUser(page, perPage) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getAuthorizedOrderItemsForUser", { page, limit: perPage });
            return response;
        });
    }
    lookupUserByEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("lookupUserByEmail", { email });
            return response;
        });
    }
    updateProfile(userInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction('updateProfile', { userInfo: userInfo });
        });
    }
    //Application Review Actions
    getApplications() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryLimit = 2000;
            const EntitlementInstance = Parse.Object.extend("EntitlementInstance");
            const queryEntInstances = new Parse.Query(EntitlementInstance);
            queryEntInstances.equalTo('member', Parse.User.current());
            queryEntInstances.limit(queryLimit);
            queryEntInstances.include("applicant");
            queryEntInstances.include("presentation");
            queryEntInstances.include("template");
            queryEntInstances.ascending("createdAt");
            const EntitlementTemplate = Parse.Object.extend("EntitlementTemplate");
            const innerQuery = new Parse.Query(EntitlementTemplate);
            innerQuery.equalTo("memberIssued", true);
            queryEntInstances.matchesQuery("template", innerQuery);
            return yield queryEntInstances.find();
        });
    }
    getApplicationDetail(applicationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getApplicationDetail", { applicationId });
            return response;
        });
    }
    entitlementCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getUnusedEntitlementCount", {});
        });
    }
    updateApplicationApproveCount(id, approveAmount, entitlementTemplateId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("updateApplicationApproveCount", {
                application_id: id,
                approve_count: approveAmount,
                for_template: entitlementTemplateId
            });
        });
    }
    fetchCartsInReview() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("fetchCartsInReview");
            return response;
        });
    }
    createNoPaymentOrder(shippingAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("createNoPaymentOrder", { shippingAddress });
            return response;
        });
    }
    createPaymentSession(subTotal, shippingAddress, packageOwners) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("createPaymentSession", { subTotal, shippingAddress, packageOwners });
            return response;
        });
    }
    getCart() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("getCart");
            return response;
        });
    }
    destroyCart(cartId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("destroyCart", { cartId });
            return response;
        });
    }
    removeAllItemsFromCart(cartItem) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("removeAllItemsFromCart", { itemsToRemove: [cartItem] });
            return response;
        });
    }
    removeItemFromCart(itemToRemove) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("removeItemFromCart", { itemToRemove });
            return response;
        });
    }
    changeCartItemQuantity(cartItem, quantity) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("changeCartItemQuantity", { item: cartItem, quantity: quantity });
            return response;
        });
    }
    changeCartItemsQuantity(cartItem) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = DataService.cloudFunction("changeCartItemsQuantity", { itemsToAdd: cartItem });
            return response;
        });
    }
    getRecommendations(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getRecommendedPackages", { forPackage: packageId });
            return response;
        });
    }
    getEvents() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getEvents");
            return response;
        });
    }
    getEvent(eventslug) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getEvent", { eventslug });
            return response;
        });
    }
    getSection(sectionslug) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getSection", { sectionslug });
            return response;
        });
    }
    getCatalog(eventslug) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getCatalog", { eventslug });
            return response;
        });
    }
    getSectionCatalog(sectionslug) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("getSectionCatalog", { sectionslug });
            return response;
        });
    }
    getPackagesList(packageIds, orderBy = "createdAt", includeSoldOut = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const Pack = Parse.Object.extend('Package');
            const query = new Parse.Query(Pack);
            if (packageIds) {
                query.containedIn('objectId', packageIds);
            }
            query.descending(orderBy);
            query.notEqualTo('deleted', true);
            if (!includeSoldOut) {
                query.notEqualTo('soldOutOverride', true);
            }
            query.include(['section.presentation.online', 'section.name']);
            const presQuery = new Parse.Query(Parse.Object.extend('Presentation'));
            presQuery.equalTo('online', true);
            presQuery.notEqualTo('deleted', true);
            const eventQuery = new Parse.Query(Parse.Object.extend('Season'));
            eventQuery.equalTo('isSelling', true);
            presQuery.matchesQuery('season', eventQuery);
            const sectionQuery = new Parse.Query(Parse.Object.extend('Section'));
            sectionQuery.matchesQuery('presentation', presQuery);
            sectionQuery.notEqualTo('deleted', true);
            query.matchesQuery('section', sectionQuery);
            query.equalTo('online', true);
            const result = yield query.find();
            return result;
        });
    }
    getAttributionLinks() {
        return __awaiter(this, void 0, void 0, function* () {
            const AttributionSource = Parse.Object.extend('AttributionSource');
            const query = new Parse.Query(AttributionSource);
            query.equalTo('promoter', Parse.User.current());
            query.notEqualTo('deleted', true);
            query.include('section.name');
            const attributionLinks = yield query.find();
            return attributionLinks;
        });
    }
    checkInventory(checkInventory) {
        return __awaiter(this, void 0, void 0, function* () {
            const inventory = yield DataService.cloudFunction("getInventoryItemQuantity", { itemOption: checkInventory });
            if (inventory.soldOutOverride) {
                return false;
            }
            else {
                return inventory.limit === 0 || (inventory.limit > 0 && inventory.availableQuantity > 0);
            }
        });
    }
    getInventoryItemQuantity(checkInventory) {
        return __awaiter(this, void 0, void 0, function* () {
            return DataService.cloudFunction("getInventoryItemQuantity", { itemOption: checkInventory });
        });
    }
    saveUserTOSAgreement() {
        return __awaiter(this, void 0, void 0, function* () {
            yield DataService.cloudFunction('agreeToTOS');
        });
    }
    hasUserAcceptedLatestTOS() {
        return __awaiter(this, void 0, void 0, function* () {
            let accepted = false;
            const user = this.currentUser();
            if (user) {
                const fetchedUser = yield user.fetch();
                const version = yield DataService.cloudFunction('termsOfServiceVersion');
                const userAgreedVersion = fetchedUser.get('termsOfServiceAgreed');
                accepted = (userAgreedVersion === version);
            }
            return accepted;
        });
    }
    markOrderForResale(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("markForResale", { orderId: orderId });
            return response;
        });
    }
    removeOrderFromResale(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("removeFromResale", { orderId: orderId });
            return response;
        });
    }
    getOrdersForResale(page, perPage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getOrdersForResale", { page, size: perPage });
        });
    }
    createResalePurchaseSession(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Parse.Cloud.run('createResalePurchaseSession', { orderId });
        });
    }
    getMarketPlaceEntries(orders) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getMarketPlaceEntries", { orders });
        });
    }
    resendVerificationCode(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction('resendEmailVerificationCode', { userId });
        });
    }
    verifyEmailVerificationCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction('verifyEmailVerificationCode', { code });
        });
    }
    getStripePublicKey(forceNewCheckout = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getStripePublicKey", { forceNewCheckout: forceNewCheckout });
        });
    }
    getPaypalClientId() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getPaypalClientId");
        });
    }
    getVirtualEvents() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getVirtualEvents");
        });
    }
    isStreamKeyValidForUser(stream_key) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("isStreamKeyValidForUser", { stream_key });
        });
    }
    getBannerForStreamKey(stream_key) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getBannerForStreamKey", { stream_key });
        });
    }
    getUserDataByImportCode(importCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction('getUserDataByImportCode', { importCode });
            console.log('[debug] user data:', result);
            return result;
        });
    }
    acceptInvitaion(userData) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction('acceptInvitation', { userData });
            console.log('[debug] user data:', result);
            return result;
        });
    }
    getVirtualEventPassesForOrder(order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getVirtualEventPassesForOrder", { order_id });
        });
    }
    getVideoMetaDataForStreamKey(stream_key) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getVideoMetaDataForStreamKey", { stream_key });
        });
    }
    getPackageForStreamKey(stream_key) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("getPackageForStreamKey", { stream_key });
        });
    }
    createOrder(cart) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield DataService.cloudFunction("purchaseOrder", { cart });
        });
    }
    getEntitlements(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            const entitlements = yield DataService.cloudFunction("getUserApplications", filters);
            return entitlements;
        });
    }
    grantEntitlmentsWithCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("grantEntitlmentsWithCode", { code });
            return result;
        });
    }
    getPackage(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const Package = Parse.Object.extend('Package');
            const query = new Parse.Query(Package);
            query.include('section');
            query.include('section.presentation');
            query.include('section.presentation.season');
            query.include('purchaseRestrictionSegmentComposition.segment');
            query.notEqualTo('deleted', true);
            query.equalTo('online', true);
            const eventPackage = yield query.get(packageId);
            return eventPackage;
        });
    }
    getPackageDetails(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const Package = Parse.Object.extend('Package');
            const query = new Parse.Query(Package);
            query.get(packageId);
            return query.first();
        });
    }
    getCommitteeMembers() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("getCommitteeMembers");
            return JSON.parse(JSON.stringify(result));
        });
    }
    getCommitteeMembersForPackage(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("getCommitteeMembersForPackage", { packageId });
            return JSON.parse(JSON.stringify(result));
        });
    }
    getDefaultCommitteeMemberForPackage(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("getDefaultCommitteeMemberForPackage", { packageId });
            return JSON.parse(JSON.stringify(result));
        });
    }
    getEntitlementTemplatesForPackagePurchaseRight(packageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("getEntitlementTemplatesForPackagePurchaseRight", { packageId });
            return JSON.parse(JSON.stringify(result));
        });
    }
    getPublicApplications() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("getPublicApplications");
            return JSON.parse(JSON.stringify(result));
        });
    }
    createApplication(committee_id, count, template) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("createApplication", { committee_id, count, template });
            return JSON.parse(JSON.stringify(result));
        });
    }
    updateApplicationMember(entitlementId, memberId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield DataService.cloudFunction("updateApplicationMember", { applicationId: entitlementId, memberId });
            return JSON.parse(JSON.stringify(result));
        });
    }
    removeCollectorFromOrderItem(orderItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("removeCollectorFromOrderItem", { orderItemId });
            return response;
        });
    }
    removePackageOwnerFromOrderItem(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("removePackageOwnerFromOrderItem", { id });
            return response;
        });
    }
    addCollectorToOrderItem(orderItemId, collector) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("addCollectorToOrderItem", { orderItemId, collector });
            return response;
        });
    }
    addPackageOwnerForOrderItem(id, ownerData) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("addPackageOwnerForOrderItem", { id, ownerData });
            return response;
        });
    }
    sendMessageToBand(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("sendMessageToBand", data);
            return response;
        });
    }
    checkIfUserExistsInSegmentComposition(segmentComposition) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield DataService.cloudFunction("checkIfUserExistsInSegmentComposition", { segmentComposition });
            return response;
        });
    }
}
DataService._instance = new DataService();
